import Main from "layouts/Main";
import React from "react";
import BankServicing from "./BankServicing";
import CustomersProfiles from "./CustomersProfiles";
import Digitalization from "./Digitalization";
import EconomicFactor from "./EconomicFactor";
import Hero from "./Hero";
import StuckInTheMiddle from "./StuckInTheMiddle";

const Segment = () => {
  return (
    <Main>
      {() => (
        <>
          <Hero />
          <EconomicFactor />
          <CustomersProfiles />
          <StuckInTheMiddle />
          <BankServicing />
          <Digitalization />
        </>
      )}
    </Main>
  );
};

export default Segment;
