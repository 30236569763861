import React, { FC, useEffect, useRef, useState } from "react";
import gsap from "gsap";
import data from "./chart-data";

import useViewPort from "hooks/useViewport";
import { Label, Text } from "./BarChart.style";

export interface IBarChartProps {
  height: number;
}

type BarProps = {
  label: string;
  height: number;
  hoverHeight: number;
  value: number;
  hoverValue: number;
  x: number;
  y: number;
};

const Bar: FC<BarProps> = ({
  label,
  value,
  height,
  hoverHeight,
  hoverValue,
  x,
  y,
}) => {
  const { width } = useViewPort();
  const pathRef = useRef<SVGPathElement>(null);
  const hoverPathRef = useRef<SVGPathElement>(null);
  const hoverController = useRef<any>(null);
  const [isHovered, setIsHovered] = useState(false);

  const isMobile = width < 767;
  const barWidth = isMobile ? 40 : 55;

  const beforeEntry = `M 0,0 L ${barWidth},30 L ${barWidth},30 L 0,0z`;
  const afterEntry = `M 0,0 L ${barWidth},30 L ${barWidth},${
    height * -1 + 30
  } L 0,${height * -1}z`;

  const hoverEntryBefore = `M 0,0 L ${barWidth},30 L ${barWidth},30 L 0,0z`;
  const hoverEntryAfter = `M 0,0 L ${barWidth},30 L ${barWidth},${
    hoverHeight * -1 + 30
  } L 0,${hoverHeight * -1}z`;

  useEffect(() => {
    gsap.to(pathRef.current, {
      attr: {
        d: afterEntry,
      },
      scrollTrigger: {
        // @ts-ignore
        trigger: pathRef.current,
        start: "top bottom+=250",
      },
    });
    hoverController.current = gsap.to(hoverPathRef.current, {
      paused: true,
      opacity: 1,
      fill: "#fff",
      attr: {
        d: hoverEntryAfter,
      },
    });
  }, []);

  return (
    <svg x={x} y={y} style={{ overflow: "visible" }}>
      <Text textAnchor="middle" x={barWidth / 2} y={height * -1 - 20}>
        {value}
      </Text>

      <path
        ref={pathRef}
        fill={isHovered ? "transparent" : "#002b47"}
        d={beforeEntry}
      />

      <path
        ref={hoverPathRef}
        fill="#009fe3"
        d={hoverEntryAfter}
        style={{ opacity: 1 }}
      />

      <Label
        textAnchor={isMobile ? "" : "middle"}
        x={barWidth / 2}
        y={isMobile ? 40 : 80}
      >
        {label}
      </Label>
      <Text
        fill="#009fe3"
        textAnchor="middle"
        x={barWidth / 2}
        y={hoverHeight * -1 - 5}
      >
        {hoverValue}
      </Text>
    </svg>
  );
};

const BarChart: FC<IBarChartProps> = ({ height }) => {
  const { width } = useViewPort();
  const ref = useRef<SVGSVGElement>(null);
  const [columnWidth, setColumnWidth] = useState(0);

  const isMobile = width < 767;
  const barWidth = isMobile ? 40 : 55;

  const makeGrid = () => {
    const svgWidth = ref.current?.getBoundingClientRect().width;
    const columnWidth = svgWidth! / data.length;
    setColumnWidth(columnWidth);
  };

  const maxValue = Math.max.apply(
    null,
    data.map(({ value }) => value)
  );

  const valueScale = maxValue / height;

  useEffect(() => {
    window.addEventListener("resize", makeGrid);
    makeGrid();
    return () => {
      window.removeEventListener("resize", makeGrid);
    };
  }, []);

  return (
    <svg width="100%" height={height} style={{ overflow: "visible" }} ref={ref}>
      {data.map((bank, i) => (
        <Bar
          label={bank.label}
          value={bank.value}
          hoverValue={bank.hoverValue}
          x={i * columnWidth + (columnWidth - barWidth) / 2}
          y={height - 80}
          height={bank.value / valueScale - 120}
          hoverHeight={bank.hoverValue / valueScale - 120}
        />
      ))}
    </svg>
  );
};

export default BarChart;
