import Container from "components/Container";
import gsap from "gsap";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import OverviewStep from "../OverviewStep";
import { Content, Legend, LegendItem, Wrapper } from "./Overview.style";

export interface IOverviewProps {}

const Overview = ({}: IOverviewProps) => {
  const legendRef = useRef<HTMLUListElement | null>(null);

  const { t } = useTranslation("architecture");

  const scrollToStep = (e: any) => {
    const stepId = e.target.id.replace("-label", "");
    document
      .querySelector(`#${stepId}`)
      ?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    const tl = gsap.timeline({ paused: true });

    tl.to(legendRef.current, {
      scrollTrigger: {
        trigger: legendRef.current,
        pin: true,
        pinSpacing: false,
        start: "center center",
        end: "bottom bottom",
        endTrigger: "#businesscase",
      },
    });

    return () => {
      tl.kill();
    };
  }, []);

  return (
    <Wrapper>
      <Legend ref={legendRef}>
        <LegendItem onClick={scrollToStep} id="mission-model-label">
          Missionmodel
        </LegendItem>
        <LegendItem onClick={scrollToStep} id="business-model-label">
          Businessmodel
        </LegendItem>
        <LegendItem onClick={scrollToStep} id="org-model-label">
          Orgmodel
        </LegendItem>
        <LegendItem onClick={scrollToStep} id="tech-model-label">
          Techmodel
        </LegendItem>
        <LegendItem onClick={scrollToStep} id="steering-model-label">
          Steeringmodel
        </LegendItem>
        <LegendItem onClick={scrollToStep} id="umsetzung-model-label">
          Umsetzung
        </LegendItem>
        <LegendItem onClick={scrollToStep} id="businesscase-label">
          Businesscase
        </LegendItem>
      </Legend>
      <Content>
        <OverviewStep
          id="mission-model"
          heading={t("step-1-heading")}
          content={t("step-1-content")}
        />
        <OverviewStep
          id="business-model"
          heading={t("step-2-heading")}
          content={t("step-2-content")}
        />
        <OverviewStep
          id="org-model"
          heading={t("step-3-heading")}
          content={t("step-3-content")}
        />
        <OverviewStep
          id="tech-model"
          heading={t("step-4-heading")}
          content={t("step-4-content")}
        />
        <OverviewStep
          id="steering-model"
          heading={t("step-5-heading")}
          content={t("step-5-content")}
        />
        <OverviewStep
          id="umsetzung-model"
          heading={t("step-6-heading")}
          content={t("step-6-content")}
        />
        <OverviewStep
          id="businesscase"
          heading={t("step-7-heading")}
          content={t("step-7-content")}
        />
      </Content>
    </Wrapper>
  );
};

export default Overview;
