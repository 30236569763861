import Heading from 'components/Heading';
import styled from 'styled-components'
import { font, rwd } from 'styles/Mixins';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

export const ProfileTitle = styled(Heading)`
  white-space: nowrap;
  padding-bottom: 58px;
  align-self: center;

  ${rwd({
    medium: `
        align-self: initial;
        padding-bottom: 0;
      `,
  })}
`;


export const IconWrapper = styled.div`
  margin: 32px 0;
  display: none;

  ${rwd({
    medium: `
      display: block;
    `,
    large: `
      margin: 60px 0;
    `
  })}
`;

export const Icon = styled.img`
  display: block;
  margin: 0 auto;
  max-width: 100%;
  height: auto;
`;

export const ValuesWrapper = styled.div`
  width: 100%;
  flex-direction: column;
  align-items: center;

  display: none;

  ${rwd({
    medium: `
      display: flex;
    `,
    large: `
      align-items: flex-start;
    `
  })}
  
`
export const ValueLabel = styled.span`
  ${font('BrownLLWeb', '19px', 400)}
  color: ${props => props.theme.colors.white};

  ${rwd({
    large: `
      font-size: 21px;
    `
  })}
`

export const AverageValue = styled.span`
  display: block;
  margin-bottom: 32px;
  ${font('BrownLLWeb', '28px', 400)}
  color: ${props => props.theme.colors.primaryBlue};

  ${rwd({
    large: `
      font-size: 65px;
    `
  })}
`


