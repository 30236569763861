import Section from "components/Section";
import styled, { css, keyframes } from "styled-components";
import { circle, position, rwd, sizing } from "styles/Mixins";
import { random } from "styles/Utils";
import slope from "./assets/slope.svg";

export const RadarSection = styled(Section)`
  overflow: hidden;
`;

export const Slope = styled.div`
  ${position("absolute", { top: 0, left: 0 })}
  ${sizing("100%", "100%")}
  background-image: url(${slope});
  background-position: left bottom;
  background-repeat: no-repeat;
`;

export const Wrapper = styled.div`
  padding: 0 34px;
  padding-bottom: 34vw;
  padding-top: ${(1330 / 1920) * 100 + "vw"};

  ${rwd({
    medium: `
      position: relative;
      ${circle((1430 / 1920) * 100 + "vw")}
      padding: 0 20px;
      padding-top: ${(1430 / 1920) * 100 + "vw"};
      ${position("absolute", { top: 0, left: "50%" })}
      transform: translateX(-50%);
    `,
  })}
`;

export const GradientWrapper = styled.div`
  ${circle((1430 / 1920) * 100 + "vw")}
  ${position("absolute", { top: 0, left: "50%" })}
  padding-top: ${(1430 / 1920) * 100 + "vw"};
  transform: translateX(-50%);
  overflow: hidden;
  min-width: 475px;
  min-height: 475px;
`;

export const Content = styled.div`
  ${rwd({
    xLarge: `
      ${position("absolute", { top: "10%", left: "-48px" })}
      width: 64%;
      z-index: 101;
    `,
    xXlarge: `
      ${position("absolute", { top: "10%", left: "-48px" })}
      width: 50%;
      z-index: 101;
    `,
  })}
`;

const createPoints = (count: number) => {
  let styles = ``;

  for (let i = 0; i < count; i++) {
    styles += `
      &:nth-child(${i + 1}) {
        top: ${random(100)}%;
        left: ${random(100)}%;
        background: #038dcb;
        animation-duration: ${random(20, 10)}s;
        animation-delay: ${-0.01 * random(2000) * (1 + 0.01 * random(2000))}s;
      }
    `;
  }

  return css`
    ${styles}
  `;
};

export const blink = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

export const PointsWrapper = styled.div`
  ${circle((1430 / 1920) * 100 + "vw")}
  ${position("absolute", { top: "0%", left: "0%" })}
  overflow: hidden;
  min-width: 445px;
  min-height: 445px;

  ${rwd({
    medium: `
      ${position("absolute", { top: "15px", left: "15px" })}
    `,
  })}
`;

export const Point = styled.div`
  position: absolute;
  z-index: 100;
  ${sizing("8px")}
  border-radius: 50%;
  ${createPoints(100)}
  animation: ${blink} ${random(0.2, 0.1)}s infinite linear;
  opacity: 0;
`;

export const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Gradient = styled.div`
  background: conic-gradient(
    from 90deg at 50% 50%,
    #012b47,
    #033858,
    #06456a,
    #08527d,
    #096090,
    #0a6fa3,
    #087eb7,
    #038dcb
  );
  ${circle((1430 / 1920) * 100 + "vw")}
  ${position("absolute", { top: 0, left: "0" })}
  overflow: hidden;
  animation: ${spin} 30s linear infinite;
  min-width: 475px;
  min-height: 475px;

  ${rwd({
    medium: `
    ${position("absolute", { top: 0, left: "0" })}
    `,
  })}
`;
