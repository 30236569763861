import React from "react";
import { Input, Wrapper } from "./TextField.style";

export interface ITextFieldProps {
  children?: any;
  placeholder?: string;
  className?: string;
  id?: string;
  name?: string;
}

const TextField = ({ placeholder, className, id, name }: ITextFieldProps) => (
  <Wrapper>
    <Input
      className={className}
      placeholder={placeholder}
      type="text"
      id={id}
      name={name}
    />
  </Wrapper>
);

export default TextField;
