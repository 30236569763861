import Main from "layouts/Main";
import React from "react";
import Episodes from "./components/Episodes";
import Hero from "./components/Hero";
import Newsletter from "./components/Newsletter";
import Project from "./components/Project";
import Transformation from "./components/Transformation";
import { Wrapper } from "./Landing.style";

export interface ILandingProps {
  children: any;
  setIsPlaying: any;
}

const Landing = ({}: ILandingProps) => (
  <Main>
    {({ setIsPlaying }: any) => (
      <>
        <Hero play={() => setIsPlaying(true)} />
        <Episodes />
        <Newsletter />
        <Project />
        <Transformation />
      </>
    )}
  </Main>
);

export default Landing;
