import styled from "styled-components";
import { font } from "styles/Mixins";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`;

export const Input = styled.input`
  width: 100%;
  height: 70px;
  border: none;
  ${font("BrownLLWeb", "20px", 400)}
`;
