import HeadingComponent from "components/Heading";
import styled from "styled-components";
import { rwd } from "styles/Mixins";

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  column-gap: 60px;
  row-gap: 60px;

  ${rwd({
    medium: `
      grid-template-columns: repeat(3, minmax(0, 1fr));
    `,
  })}
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    margin: 30px auto;
  }
`;

export const Heading = styled(HeadingComponent)`
  font-size: 21px;
  color: ${(props) => props.theme.colors.primaryBlue} !important;
  text-align: left;
  display: block;
  margin: 0;
  width: 100%;
  padding: 24px 0 !important;
`;
