import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next)
  // init i18nextns
  .init({
    fallbackLng: "de",
    debug: false,
    lng: "de",
    preload: ["de"],
    ns: ["landing"],
    defaultNS: "landing",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      de: {
        landing: {
          "footer-privacy": "Datenschutz",
          "footer-imprint": "Impressum",
          "menu-0": "Landing",
          "menu-1": "Das Segment",
          "menu-2": "Der Wettbewerb",
          "menu-3": "Die Strategie",
          "menu-4": "Die Umsetzung",
          "discover-more": "Entdecken",
          "heading-0": "KMU-Banking\n der Zukunft",
          "heading-1": "Verstehen.",
          "cta-video": "Prototyp-Video\n starten",
          "cta-demo": "Jetzt persönliche\n Demo anfordern",
          "cta-demo-button": "Demo anfordern",
          "content-1":
            "Lassen Sie uns gemeinsam die Kundenvielfalt, den Wettbewerb, die strategischen Dimensionen und die notwendige Umsetzung im digitalen KMU-Banking genauer beleuchten.",
          "content-1-item-1-heading": "Das Segment",
          "content-1-item-1-content":
            "Warum ist das KMU-Segment so relevant? Was ist charakteristisch für KMU?",
          "content-1-item-2-heading": "Der Wettbewerb",
          "content-1-item-2-content":
            "Wer sind die relevanten Player von heute und wer von morgen?",
          "content-1-item-3-heading": "Die Strategie",
          "content-1-item-3-content":
            "Welche Dimensionen müssen beleuchtet und spezifisch ausgestaltet werden?",
          "content-1-item-4-heading": "Die Umsetzung",
          "content-1-item-4-content":
            "Worauf kommt es bei der Umsetzung an? Was sind die wahren Erfolgsfaktoren?",
          "content-1-discover": "Entdecken",
          "newsletter-heading": "Diskutieren.",
          "newsletter-content":
            "Niemand weiß, wohin sich das Firmenkundengeschäft ganz konkret entwickeln wird. Lassen Sie uns gemeinsam in die Glaskugel schauen und diskutieren. Nur im gegenseitigen Austausch können neue Ideen und Erkenntnisse reifen.",
          "newsletter-form-content":
            "Bleiben Sie in Kontakt und erhalten Sie die wichtigsten Updates von kmubanking.digital",
          "newsletter-input-placeholder": "E-Mail-Adresse eingeben",
          "newsletter-form-send": "Absenden",
          "heading-3": "Gestalten.",
          "content-3":
            "Die Zukunft des Firmenkundengeschäfts passiert genau in diesem Moment. Stehen bleiben und zugucken oder anpacken und selbst gestalten? Gemeinsam finden wir die große Vision und setzen diese in pragmatischen Schritten um.",
          "content-3-button-1": "Demo anforden",
          "content-3-button-2": "Video starten",
          "heading-4":
            "kmubanking.digital ist die neue Heimat für alle, die beim digitalen Wandel im Firmenkundengeschäft mittendrin sein wollen statt nur dabei",
          "content-4-item-1-heading": "Fokussiert",
          "content-4-item-1-content":
            "kmubanking.digital dreht sich exklusiv um das Firmenkundengeschäft von Finanzdienstleistern.",
          "content-4-item-2-heading": "Wachsend",
          "content-4-item-2-content":
            "kmubanking.digital ist und bleibt ein Arbeitsstand. Neue Erkenntnisse werden fortlaufend aktualisiert.",
          "content-4-item-3-heading": "Lebendig.",
          "content-4-item-3-content":
            "kmubanking.digital ist kein Monolog, sondern Grundlage für einen möglichst lebendigen Austausch unter echten Gestalterinnen.",
        },
        customer: {
          "hero-paragraph-heading": "Mehr als 20 Mio. KMU in Europa",
          "hero-paragraph-content":
            "Kleinst-, kleine und mittelgroße Unternehmen bis 50 Mio. Euro Jahresumsatz (inkl. Einzelselbständigen) machen sowohl in Europa als auch in Deutschland mehr als 99% aller Unternehmen aus. Sie beschäftigen knapp zwei Drittel aller Beschäftigten und umfassen knapp 57% der gesamten Wertschöpfung.",
          "heading-1": "Auch in Deutschland ein echter Wirtschaftsfaktor",
          "content-1":
            "In Deutschland stehen den rund 44,6 Mio. erwerbstätigen Menschen, ca. 3,3 Mio. KMU gegenüber. Dabei macht der weitaus größte Teil der KMU weniger als 1 Mio. Euro Jahresumsatz. Branchenschwerpunkte liegen traditionell im verarbeitenden Gewerbe sowie im Baugewerbe.",
          "heading-2": "Segmentierungen gibt es so viele wie Banken",
          "content-2":
            "Kein Segment der deutschen Wirtschaft ist so heterogen wie KMU: vom kleinen Handwerker um die Ecke bis zum kapitalmarktorientierten Anlagenbauer. Die Antwort der Banken auf die 'Sandwichposition' der KMU zwischen Retail-Mengengeschäft und Großunternehmen-Individualbetreuung fällt sehr unterschiedlich aus. Die Aufteilung der Segmente und die Zuordnung zum Privatkunden- vs. Firmenkundenressort unterlag dabei historisch immer wieder Veränderungen.",
          "content-2-chart-heading": "",
          "heading-3": "Alles KMU oder was?",
          "content-3":
            "KMU-Banking muss wie jedes andere Produkt und jede andere Dienstleistung auf der Welt zu den Kunden und deren Anforderungen passen. Die Diversität in den Geschäftsmodellen und Alltagsabläufen erlauben im KMU-Segment kein 'One size fits all'. ",
          "content-3-item-1-heading": "Kleinstunternehmen",
          "content-3-item-2-heading": "Kleine Unternehmen",
          "content-3-item-3-heading": "Mittlere Unternehmen",
          "content-3-item-1-turnover": "250000",
          "content-3-item-1-employees": "3",
          "content-3-list-heading-1": "Governance",
          "content-3-list-heading-2": "Finanzverantwortung",
          "content-3-list-heading-3": "Buchführung",
          "content-3-list-heading-4": "Anzahl Bankpartner",
          "content-3-list-heading-5": "Banking-Verhalten",
          "content-3-item-2-turnover": "8000000",
          "content-3-item-3-turnover": "40000000",
          "content-3-item-2-employees": "30",
          "content-3-item-3-employees": "80",
          "content-3-item-1-list-1": "Inhaberin = Geschäftsführerin",
          "content-3-item-1-list-2":
            "kfm. Leitung/Finanzierung/Banking Teil der GF-Aufgaben, keine eigene Disziplin",
          "content-3-item-1-list-3":
            "im Wesentlichen ausgelagert an Steuerberaterung",
          "content-3-item-1-list-4":
            "ein primärer Finanzierungspartner, ggfs. 1 bis max. 2 weitere Kontoverbindungen",
          "content-3-item-1-list-5":
            "nutzt Online-Banking im Browser, ca. 2-3x pro Woche",
          "content-3-item-2-list-1": "Inhaberin = Geschäftsführerin",
          "content-3-item-2-list-2": "Banking/Buchhaltung als eigene Stelle",
          "content-3-item-2-list-3":
            "intern, Steuerberatung macht die formellen Abschlüsse",
          "content-3-item-2-list-4":
            "1-2 primäre Kreditpartner, zzgl. bis zu zwei alternative Finanzierer für Leasing/Factoring, 2-3 Kontoverbindungen",
          "content-3-item-2-list-5":
            "nutzt (Offline-)Banking-Software, Buchhaltung prüft Kontostände täglich, GF prüft 2x pro Woche",
          "content-3-item-3-list-1": "Fremd-Geschäftsführung/Management",
          "content-3-item-3-list-2": "Kfm. Leitung mit Unterfunktionen",
          "content-3-item-3-list-3":
            "intern, Steuerberatung macht ggfs. noch die formellen Abschlüsse",
          "content-3-item-3-list-4":
            "bis zu 3 gleichwertige Hauptbankpartner, zzgl. weiterer Finanzierer für Leasing/Factoring, 3-4 Kontoverbindungen",
          "content-3-item-3-list-5":
            "ERP-Software, DATEV-Anbindung, Buchhaltung prüft Kontostände 2x täglich, kfm. Leitung prüft 2-3x pro Woche",
          "stuck-in-the-middle-content-heading":
            "Kein einfacher Weg zu 16,6 Mrd. Euro KMU-Wallet p.a",
          "stuck-in-the-middle-content":
            "Das KMU-Segment ist aus Bankensicht in einer anspruchsvollen „Sandwich-Position“. Das Ertragspotenzial für Finanzdienstleister pro Jahr entspricht nahezu exakt dem Wallet mit Großunternehmen (Firmen ab 50 Mio. Euro Jahresumsatz). Die Geschäftsstruktur mit einem durchschnittlichen Kunden-Wallet von 5.100 Euro p.a. ähnelt hingegen stärker dem Mengengeschäft aus dem insgesamt dreimal so großen Privatkundensegment. Anders als bei Großunternehmen (ø-Einzelwallet: 1,29 Mio. Euro p.a.) muss der gehobene Beratungsanspruch aus den Anforderungen der geschäftlichen Perspektive im KMU-Segment daher ähnlich effizient wie im Privatkundenbereich gedeckt werden.",
          "cir-wunder-heading": "Digitalisierung ist das notwendige CIR-Wunder",
          "cir-wunder-content":
            "Die hohe Anzahl an Kunden gepaart mit dem gesteigerten Beratungsbedarf bei gleichzeitig limitiertem Wallet haben die CIR im KMU-Segment bei den meisten Instituten in der Regel in oberen, wenig profitablen Bereichen verweilen lassen in der Vergangenheit. Insb. Unternehmen bis 10 Mio. Euro Jahresumsatz sind bislang von nur wenigen Banken profitabel zu betreuen gewesen. Das Digital-Paradoxon erlaubt nun die Quadratur des Kreises: effektive, quasi-individuelle Betreuung und Monetarisierung der Kunden bei gleichzeitig hoher Effizienz in den Prozessen.",
          "heading-4": "Digitalisierung ist das notwendige CIR Wunder",
          "content-4":
            "Die hohe Anzahl an Kunden im KMU-Segment gepaart mit dem gesteigerten Beratungsbedarf bei gleichzeitig limitiertem Wallet lassen die CIR bei den meisten Instituten in der Regel in oberen, wenig profitablen Bereichen verweilen. Insb. Unternehmen bis 10 Mio. Euro sind bislang von nur wenigen Banken profitabel zu betreuen. Ein „vernachlässigtes“ Dasein zwischen Privat- und Firmenkundensegment ist vielfach die Folge. Mit einem digitalen Verständnis des KMU-Bankings ist nun die Quadratur des Kreises möglich: neue KMU-Kunden gewinnen und ein profitables Digitalbanking für KMU aufbauen.",
        },
        competition: {
          "hero-heading-1": "Marktanteile der Incumbents je Segment",
          "hero-paragraph-heading":
            "Etablierte Banken dominieren das KMU-Segment",
          "hero-paragraph-content":
            "Die Wettbewerbsverhältnisse im Banking für KMU waren in der Vergangenheit weitestgehend in Stein gemeißelt: Sparkassen sowie Genossenschaftsbanken sind die dominierenden Partner für die zumeist stark regional verankerten Unternehmen. Den verbleibenden Markt teilen sich die Deutsche Bank, Commerzbank und UniCredit HypoVereinsbank weitgehend untereinander auf und ergänzen vielfach das Set an bis zu 3-4 Bankverbindungen eines gehobenen KMU. Fintechs drängen zwar zunehmend in den Mittelpunkt der öffentlichen und medialen Aufmerksamkeit. In der Gesamtbetrachtung spielen sie dennoch bisher eine untergeordnete Rolle.",
          "heading-1": "Challenger aus unterschiedlichen Richtungen",
          "content-1":
            "Der Wettbewerb um die KMU-Kundschaft in Deutschland verändert sich rasch. Neben den Digitalisierungsbemühungen der etablierten Banken drängen neue Spieler in den Markt. Challengerbanken wie Penta oder Finom werden vom Einstiegsklientel Startups zunehmend in die gesamte Breite des KMU-Segments drücken. Kontist fokussiert (bisher) ausschließlich auf Selbständige und schafft sich in der Nische eine begeisterte Gefolgschaft. Für Qonto ist Deutschland der vierte Markt, auf dem sie aktiv sind. Sie haben dabei ein Investoren-Funding von über 100 Mio. Euro im Gepäck. B2C-Neobanken interessieren sich zunehmend für gewerbliche Kunden und bringen eine ausgereifte, vom (Privat-)Kunden her gedachte digitale Plattform mit. Daneben droht Disruption durch Fintechs, die sich auf einzelne Produkte oder spezielle Kundengruppen spezialisiert haben. Fyrst – als Marke der Deutsche Bank / Postbank – ist der bisher einzige Versuch einer etablierten Bank unter neuer, eigenständiger Marke diesen Wettbewerb entgegenzutreten.",
          "content-1-heading-1":
            "Monoliner-Fintechs atomisieren die Kundenverbindung",
          "content-1-content-1":
            "Die erste B2B-Fintech-Welle hat viele insbesondere aufs Lending spezialisierte Fintechs hervorgebracht. Während sich dieser Markt aktuell in seiner Konsolidierung befindet - sich also pro Kategorie die Champions herauskristallisieren - hat diese Phase dennoch bewiesen: die Spielbedingungen aus dem B2C-Banking werden auch im KMU-Segment greifen. Digitale, einfache und stringent vom Kunden her gedachte Geschäftsmodelle werden nicht zwingend im einzelnen, aber doch ganz sicher in ihrem Grundsatz im Markt Fuß fassen. In ihrer Gesamtheit atomisieren die auf Nischen spezialisierten Anbieter sukzessive die Kundenverbindung. Etablierte Banken können sich in diesem Umfeld entweder zur Orchestratorin der verschiedenen Angebote an der Kundenschnittstelle wandeln, aufstrebende Nischenplayer akquirieren oder mit eigenen Lösungen ihr aktuelles Spielfeld verteidigen. Auch wenn für die Banken heute ertragsmäßig noch kein Risiko besteht, gilt daher dennoch: die Zeit läuft.",
          "content-1-heading-2":
            "Ökosysteme und Vergleichsplattformen übernehmen die Kundenverbindung",
          "content-1-content-2":
            "Während die einen Fintechs den Angriff in der Nische wagen, streben andere Player umfassender an die Kundenschnittstelle. Vergleichsplattformen (u. a. Compeon, Fincompare) wie auch  Ökosystem-Player (u.a. aifinyo) wollen ihren digitalen Geburts-Vorsprung nutzen, um sich als Orchestrator im Zentrum der Kundenverbindung zu platzieren. Die Vergleichsplattformen bieten den Kunden mit ihrem ausgereiften Vertriebsmodell und dem daraus resultierenden Vermittlungs- und Vergleichsangebot eine bisher vielfach ungekannte Wahlfreiheit. Ökosysteme hingegen versprechen in erster Linie die Convenience eines nahtlosen Zusammenspiels mehrerer, auf das jeweilige Kundensegment zugeschnittener Produkte und Near- sowie Beyond-Banking-Leistungen. Beide Ansätze bedienen Grundbedürfnisse der Entscheiderinnen auf KMU-Seite und werden damit auch langfristig ihren Platz im Wettbewerb haben.",
          "heading-2": "Disruption bleibt aus, Wachablösung kommt",
          "content-2":
            "Die Parallelen zum Privatkundengeschäft zeigen deutlich: das KMU-Banking befindet sich in der Dämmerung. Noch sind neue digitale Player zu klein für einen offensichtlichen Effekt auf die Gewinn- und Verlustrechnungen der amtierenden Banken. Aufgrund der regulatorischen Rahmenbedingungen wird auch im KMU-Segment keine plötzliche Disruption den Markt ergreifen. Die aktuelle Position von Penta, Kontist, Finom & Co. erinnert dabei an die zunächst überschaubaren Marktanteilsgewinne von ING und DKB zu Beginn des Jahrhunderts ebenso wie an den Eintritt der Neo-Banken rund um N26 in den letzten 5+ Jahren. Die Gefahr kommt aus der Nische und wird anfangs unterschätzt oder nicht ernst genommen. Dennoch sind die initialen Fintechs weit über die erste Startup-Phase hinausgewachsen. Die Disruption findet vielmehr in den Grundfesten des Geschäftes statt: Zuvor unrentable und dadurch verwaiste Geschäftsfelder werden im Digitalen durch neue Player mit schlankeren, umsetzungsstärkeren Strukturen wiederbelebt.",
        },
        architecture: {
          "pyramid-1": "1 Mission Model",
          "pyramid-2": "2 Business Model",
          "pyramid-3": "3 Org Model",
          "pyramid-4": "4 Tech Model",
          "pyramid-5": "5 Steering Model",
          "step-1-heading":
            "Mission Model - den richtigen Ausgangspunkt wählen",
          "step-1-content":
            "Vision, Mission, Purpose. Unabhängig von der letztlichen Detailausgestaltung ist die gemeinsame Zielvorstellung und ein gemeinsames „Wofür stehen wir?“ die Grundvoraussetzung für einen spürbaren Impact im Markt und damit auch für spürbaren Erfolg im KMU-Geschäft.",
          "step-2-content":
            "Die Digitalisierung des KMU-Geschäfts erfordert klare, mutig-unternehmerische Entscheidungen. Bei welchen Kunden und mit welchem Gesamtoffering können Sie einen USP am Markt etablieren?",
          "step-3-content":
            "Das Operating Model setzt den Ansprüchen aus Mission und Business Model den organisatorischen Rahmen. Aufbauorganisation, Prozesse und vor allem das Personal sind entscheidende Faktoren.",
          "step-4-content":
            "Die Bedeutungszunahme der IT ist omnipräsent im digitalen Banking. Beim Umbau des KMU-Geschäfts als Antwort auf den schnelllebigen Wandel im Markt die richtige IT-Architektur zu finden, ist längst erfolgskritisch.",
          "step-5-content":
            "Modernes KMU-Banking benötigt eine moderne Steuerung. Von der Budgetierung bis zum Risikomanagement bietet sich eine Bandbreite zentraler und erfolgskritischer Fragestellungen.",
          "step-6-content":
            "Das wahre KMU-Banking findet letztlich nicht in Mission Model, Business Model oder Operating Model statt, sondern in der Live-Situation am Markt. Die Ideen aus der Theorie in ein neuartiges, besseres Kundenerlebnis zu überführen, bedarf einer konsequenten Umsetzungsorientierung.",
          "step-7-content":
            "Letztliche Triebkraft unternehmerischen Handelns ist der ökonomische Erfolg. Langfristig stellt sich dieser Erfolg nur mit einem in jeder Hinsicht nachhaltigen Geschäftsmodell ein. Der Business Case soll die Wirkungsmechanismen des digitalen KMU-Bankings offenlegen und rationale Entscheidungen zur Gestaltung des KMU-Bankings ermöglichen.",
          "step-2-heading": "Business Model - die strategischen Weichen setzen",
          "step-3-heading":
            "Operating Model - Organisation ist das halbe Leben",
          "step-4-heading": "Tech Model - Banking wird (wieder) zum TechGame",
          "step-5-heading":
            "Steering Model - den richtigen Kurs stets im Blick",
          "step-6-heading":
            "Umsetzung und Skalierung - sich der Realität stellen",
          "step-7-heading":
            "Business Case - das oberste Ziel: ökonomischer Erfolg",
        },
        solution: {
          "hero-paragraph-heading": "Let's get\nit on",
          "hero-paragraph-subheading":
            "Visionen ohne Umsetzung sind nur Halluzination",
          "content-item-1-heading": "Vermarktung heißt Fokussierung",
          "content-item-1-content":
            "Im operativen Marktauftritt zeigt sich die Qualität der strategischen Weichenstellungen. Mutige Fokussierung auf spitzere Zielgruppen, klare Trennung von Neukunden- und Bestandskundenwelt (Login) und hohe Anmutung in der UI/UX setzen ein unübersehbares Ausrufezeichen. Nur wer bereits in der Vermarktung die Unternehmen sowie ihre Herausforderungen versteht und in den Mittelpunkt stellt, schafft eine effiziente Neukundenansprache zu attraktiven Customer Acqusition Costs (CAC).",
          "content-item-2-heading":
            "Onboarding als Erlebnis statt Conversion-Killer",
          "content-item-2-content":
            "Im Onboarding zählt es: wird die Interessentin zu einer echten Kundin? Dabei sollte der Fokus weniger auf dem klassischen (regulatorischen) Kundenbegriff liegen, als vielmehr auf einem modernen User-Verständnis. Niedrigschwellige Einstiege (auch ohne KYC) schaffen, UX für minimale und intuitive Eingaben optimieren, Prozesstransparenz sicherstellen und dabei das Onboarding als fortwährende Aufgabe verstehen - die Grundanforderungen sind klar, doch in der Praxis noch zu selten in Umsetzung.",
          "content-item-3-heading": "Nach dem Login ist vor dem Produkt",
          "content-item-3-content":
            "Der Login muss zu einem magischen (und gleichzeitig selbstverständlichen) Moment werden für die KMU. Die intuitive Bedienbarkeit gewährt Zugang zu den Features und Produkten der Bank. Klassische Bankdienstleistungen sind dabei mit Near- und Beyond-Banking-Features nahtlos verzahnt. Datenbasiert wird den KMU die passende Lösung im richtigen Moment und an der richtigen Stelle bereitgestellt - als 'Second Line of Offense' durch die Beraterinnen unterstützt. Hauptaugenmerk ist die Kollaboration aller Beteiligten auf der digitalen Plattform (innerhalb des KMU, zwischen Bank und KMU sowie innerhalb der Bank).",
          "content-item-4-heading": "Die KMU-Sicht ist nur die halbe Wahrheit",
          "content-item-4-content":
            "Digitales KMU-Banking endet nicht am Kunden-Frontend – „Berater-second“ wird als Effektivitätsschub in der Kundenbindung und -durchdringung digital aufgeladen. Die kostbare, weil knappe Ressource Beratungszeit wird auf den maximalen Impact in der Digital-First-Kundenbeziehung optimiert. Vertriebsfernere Bereiche arbeiten digital-unterstützt Hand-in-Hand mit dem Beratungsteam. Die Kollaboration innerhalb der Bank wird nicht verordnet, sondern durch eine ausgezeichnete, gelenkte User Experience für ALLE internen Mitarbeiterinnen von allein zur Selbstverständlichkeit.",
        },
      },
    },
  });

export default i18n;
