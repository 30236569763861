import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { font, position, rwd, sizing } from "styles/Mixins";

export const Wrapper = styled.div<{ isAttached: boolean; isOpen: boolean }>`
  ${position("fixed", { top: 0, left: 0 })}
  ${sizing("100%", "80px")}
  overflow: hidden;
  z-index: 100;

  ${(props) =>
    props.isOpen &&
    `
    ${sizing("100%", "100%")}
    @supports (-webkit-overflow-scrolling: touch) {
      height: calc(100vh - 108px);
    }
  `}
  transform: translateY(-100%);

  padding: 16px 34px;
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: column;

  ${rwd({
    medium: `
      ${sizing("100%", "80px")}`,
  })}

  ${(props) =>
    props.isAttached &&
    `
      background: rgba(0, 0, 0, 0.2);
      backdrop-filter: blur(20px);
      transform: translateY(0);
      padding: 8px 34px;
      z-index: 100;

      ${rwd({
        small: `
            padding: 12px ${(92 / 1920) * 100}%;
        `,
      })}

  `}
`;

export const TopWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;

  ${rwd({
    medium: `
      height: 100%;
    `,
  })}
`;

export const LogoLink = styled(NavLink)`
  svg * {
    fill: ${(props) => props.theme.colors.white};
  }
`;

export const Links = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: none;
  width: 100%;

  ${rwd({
    large: `
      display: flex;
      justify-content: center;
    `,
  })}
`;

export const LinkItem = styled.li<{ isActive: boolean }>`
  ${font("BrownLLWeb", "25px", 400)}
  margin: 0;
  transition: 0.2s linear opacity;
  overflow: hidden;
  position: relative;
  line-height: 60px;

  &:after {
    ${sizing("100%", "2px")}
    margin-top: 4px;
    content: "";
    background-color: ${(props) => props.theme.colors.white};
    transform: translateX(-101%);
    transition: transform 0.3s ease-in-out;
    display: none;
  }

  &:hover {
    opacity: 1;
  }

  &:hover:after {
    transform: translateX(0);
  }

  ${rwd({
    medium: `
      line-height: 24px;
      ${font("BrownLLWeb", "21px", 400)}
      margin: 0 13px;
      &:after {
        display: block;
      }
    `,
  })}

  ${(props) =>
    props.isActive &&
    `
      opacity: 1;

      &:after {
        transform: translateX(0) !important;
      }
  `}
`;

export const MobileNavs = styled.ul<{ isOpen: boolean }>`
  flex-direction: column;
  margin: 0;
  padding: 0;
  flex: 1;
  padding-top: 100px;
  display: none;
  height: 100vw;

  ${(props) => props.isOpen && `display: flex;`}

  ${rwd({
    medium: `
      display: none;
    `,
  })}
`;

export const Link = styled(NavLink)`
  text-decoration: none;
  color: inherit;
  color: ${(props) => props.theme.colors.white};
`;

export const Trailer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;

  cursor: pointer;
  margin-right: 40px;

  ${rwd({
    medium: `
      margin-right: 0;
    `,
  })}
`;

export const TrailerLabel = styled.div`
  ${font("BrownLLWeb", "21px", 400)}
  color: ${(props) => props.theme.colors.white};
  margin-right: 12px;
  display: none;
  cursor: pointer;

  ${rwd({
    small: `
      display: initial;
    `,
  })}
`;

export const Footer = styled.div`
  margin-top: auto;

  ${rwd({
    medium: `
       display: none;
    `,
  })}
`;

export const Social = styled.div`
  display: flex;
  justify-content: center;

  padding-bottom: 30px;
  column-gap: 50px;
  align-items: center;

  ${rwd({
    medium: `
       justify-content: flex-end;
    `,
  })}
`;

export const Copyrights = styled.div`
  background: ${(props) => props.theme.colors.secondaryBlue};
  width: 100%;
  color: ${(props) => props.theme.colors.white};
  display: flex;
  justify-content: center;
  margin-top: auto;
  flex-direction: column;

  ${rwd({
    small: `
     flex-direction: row;
    `,
  })}
`;

export const Copyright = styled.span`
  display: block;
  text-align: center;
  grid-area: copyright;
  color: ${(props) => props.theme.colors.white};
  ${font("BrownLLWeb", "16px", 400)}
  order: 2;
  margin-top: 40px;
  justify-content: center;
  padding: 0 16%;
  line-height: 24px;

  ${rwd({
    small: `
      ${font("BrownLLWeb", "20px", 400)}
      text-align: left;
      margin-top: 0;
      padding: 0;
    `,
    medium: `
      justify-content: flex-start;
      order: 1;
      width: 100%;
    `,
  })}
`;

export const FooterLinks = styled.ul`
  order: 1;
  grid-area: links;
  display: flex;
  align-items: center;
  flex-direction: column;
  list-style: none;
  padding: 0;
  align-self: end;
  width: 100%;
  margin: 0;

  ${rwd({
    medium: `
      flex-direction: row;
      justify-content: flex-end;
      order: 2;
      margin: 0 -24px;
    `,
  })}
`;

export const FooterLinkItem = styled.li`
  ${font("BrownLLWeb", "21px", 400)}
  color: ${(props) => props.theme.colors.white};
  line-height: 35px;
  padding: 0px 24px;

  a {
    color: inherit;
    text-decoration: none;
  }

  ${rwd({
    small: `
      padding: 0 24px;
    `,
  })}
`;
