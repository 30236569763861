import React from "react";
import {
  Caption,
  Circle,
  InnerCircle,
  StyledHeading,
  TextContent,
  Wrapper,
} from "./Hero.style";
import Heading from "components/Heading";
import { useTranslation } from "react-i18next";
import Paragraph from "components/Paragraph";
import gsap from "gsap/all";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const Hero = (): JSX.Element => {
  const { t } = useTranslation("customer");

  return (
    <Wrapper>
      <Caption l={1} text={`too big\n to be\n ignored`} />
      <Circle>
        <InnerCircle />
      </Circle>
      <TextContent>
        <StyledHeading l={3} text={t("hero-paragraph-heading")} />
        <Paragraph text={t("hero-paragraph-content")} />
      </TextContent>
    </Wrapper>
  );
};

export default Hero;
