import React, { FC, useEffect, useRef, useState } from "react";
import gsap from "gsap";
import { useSwipeable } from "react-swipeable";

import bankingSegments from "./data.json";
import {
  Block,
  BlockLabel,
  BlockValue,
  Canvas,
  Chart,
  SegmentLabel,
  Track,
  TrackLabel,
  TrackLabelCap,
  Wrapper,
  Swipe,
  PaginationWrapper,
  Dot,
} from "./SegmentsChart.style";

export interface ISegmentsChartProps {}

const SegmentsChart: FC<ISegmentsChartProps> = ({}) => {
  const { ref } = useSwipeable({
    onSwipedLeft() {
      if (currentSegment > -4) {
        setCurrentSegment(currentSegment - 1);
      }
    },
    onSwipedRight() {
      if (currentSegment < 0) {
        setCurrentSegment(currentSegment + 1);
      }
    },
  });
  const wrapperRef = useRef<any>(null);
  const [isMobile, setIsMobile] = useState(false);
  const gap = 10;
  const columnsCount = bankingSegments.length;
  const gutterOverallWidth = (columnsCount - 1) * gap;
  const overallColumnsWidth = 1396 - (isMobile ? 240 : 358);
  const [currentSegment, setCurrentSegment] = useState(0);
  const columnWidth = (overallColumnsWidth - gutterOverallWidth) / columnsCount;

  useEffect(() => {
    const cb = () => {
      const { innerWidth } = window;
      setIsMobile(innerWidth < 767);
    };

    window.addEventListener("resize", cb, true);
    cb();
    return () => {
      window.removeEventListener("resize", cb);
    };
  }, []);

  useEffect(() => {
    gsap.to(`.block`, {
      duration: 1,
      fill: "#ADE0F5",
      invalidateOnRefresh: true,
      immediateRender: false,
      stagger: {
        grid: "auto",
        from: "end",
        axis: "y",
        amount: 1.5,
      },
      scrollTrigger: {
        invalidateOnRefresh: true,
        trigger: ".blocks",
        start: "top bottom",
      },
    });

    gsap.to(`.block-label`, {
      duration: 1,
      delay: 0.5,
      fill: "#002b47",
      invalidateOnRefresh: true,
      immediateRender: false,
      stagger: {
        grid: "auto",
        from: "end",
        axis: "y",
        amount: 1.5,
      },
      scrollTrigger: {
        invalidateOnRefresh: true,
        trigger: ".blocks",
        start: "top bottom",
      },
    });

    gsap.to(`.block-value`, {
      duration: 1,
      delay: 0.75,
      fill: "#009fe3",
      invalidateOnRefresh: true,
      immediateRender: false,
      stagger: {
        grid: "auto",
        from: "end",
        axis: "y",
        amount: 1.5,
      },
      scrollTrigger: {
        invalidateOnRefresh: true,
        trigger: ".blocks",
        start: "top bottom",
      },
    });
  }, []);

  return (
    <Wrapper ref={ref}>
      <Canvas className="canvas">
        <Chart ref={wrapperRef} viewBox="0 0 1396 764">
          <defs>
            <clipPath id="columns" clipPathUnits="objectBoundingBox">
              <rect x="200" y="0" width="3000" height="4000" />
            </clipPath>
          </defs>
          <Track y="278" />

          <g>
            <TrackLabel x="25" y="317">
              KMU
            </TrackLabel>
            <TrackLabelCap x="25" y="347">
              &lt; 50 MEUR
            </TrackLabelCap>
          </g>
          {console.log(currentSegment)}
          <PaginationWrapper>
            <Dot isActive={currentSegment === 0} cx="197" cy="734" r="6" />
            <Dot isActive={currentSegment === -1} cx="215" cy="734" r="6" />
            <Dot isActive={currentSegment === -2} cx="233" cy="734" r="6" />
            <Dot isActive={currentSegment === -3} cx="251" cy="734" r="6" />
            <Dot isActive={currentSegment === -4} cx="269" cy="734" r="6" />
          </PaginationWrapper>

          <clipPath id="segments">
            <rect x="240" width="400" height="732" />
          </clipPath>

          <g className="blocks">
            {bankingSegments.map((segment, idx) => {
              const x =
                (isMobile ? 1399 : 1396) -
                (idx + 1 - currentSegment) * columnWidth -
                (idx - currentSegment) * gap;

              let y = 654;

              return segment.blocks.map((block, blockIdx) => {
                y = y - block.height;

                if (blockIdx) {
                  y = y - gap;
                }

                return (
                  <g clipPath="segments">
                    <Block
                      className="block"
                      x={x}
                      y={y}
                      width={columnWidth}
                      height={block.height}
                      fill={block.fill}
                    />

                    <BlockLabel
                      className="block-label"
                      x={x + columnWidth / 2}
                      y={y + block.height / 2 + 6 - (block.value ? 12 : 0)}
                      textAnchor="middle"
                    >
                      {block.label}
                    </BlockLabel>
                    {block.value && (
                      <BlockValue
                        className="block-value"
                        x={x + columnWidth / 2}
                        y={y + block.height / 2 + 6 + 12}
                        textAnchor="middle"
                      >
                        {block.value}
                      </BlockValue>
                    )}

                    <SegmentLabel
                      x={x + columnWidth / 2}
                      y={654 + 40}
                      textAnchor="middle"
                    >
                      {segment.label}
                    </SegmentLabel>
                  </g>
                );
              });
            })}
          </g>
        </Chart>
      </Canvas>
    </Wrapper>
  );
};

export default SegmentsChart;
