import styled from "styled-components";
import { flexCentered } from "styles/Layout";
import { position, rwd, sizing } from "styles/Mixins";

import SectionComponent from "components/Section";

export const Section = styled(SectionComponent)`
  display: none;

  ${rwd({
    medium: `
    display: block;
       
    `,
  })}
`;

export const Wrapper = styled.div`
  position: relative;
  height: auto;
  display: none;

  ${rwd({
    medium: `
    display: block;
      height: 600vh;  
    `,
  })}
`;

export const TabletWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -110px;
  left: 0;
  right: 0;

  ${rwd({
    medium: `
      display: flex;
      align-items: center;
      justify-content: center;
      position: sticky;
      top: 0;
      height: 100vh;
    `,
  })}
`;

export const Tablet = styled.img`
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;

  ${rwd({
    medium: `
      max-width: ${(1400 / 1920) * 100}vw;
    `,
  })}
`;

export const TooltipOne = styled.img`
  position: absolute;
  top: 4%;
  margin-top: 40vh;
  right: 0;
  max-width: ${(638 / 1920) * 100}vw;
  height: auto;
  transition: opacity 0.3s ease-in;
  opacity: 0;
`;

export const TooltipTwo = styled.img`
  position: absolute;
  top: 14%;
  margin-top: 60vh;
  left: 0;
  max-width: ${(408 / 1920) * 100}vw;
  height: auto;
  opacity: 0;
  transition: opacity 0.3s ease-in;
`;

export const TooltipThree = styled.img`
  position: absolute;
  top: 30%;
  margin-top: 80vh;
  right: 0;
  max-width: ${(638 / 1920) * 100}vw;
  height: auto;
  opacity: 0;
  transition: opacity 0.3s ease-in;
`;

export const TooltipFour = styled.img`
  position: absolute;
  top: 32%;
  margin-top: 90vh;
  right: 0;
  max-width: ${(638 / 1920) * 100}vw;
  height: auto;
  transition: opacity 0.3s ease-in;
  opacity: 0;
`;
