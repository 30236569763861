import styled  from "styled-components";
import { rwd } from "styles/Mixins";

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-auto-rows: min-content;
  row-gap: 68px;
  padding: 60px 0;

  ${rwd({
    medium: `
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-auto-rows: min-content;
      column-gap: 96px;
      padding: 120px 0;
    `,
  })}

`;