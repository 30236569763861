import styled from "styled-components";
import { rwd } from "styles/Mixins";

export const Figure = styled.figure`
  max-width: 100%;
  margin: 0;
  margin-bottom: 32px;

  ${rwd({
    medium: `flex-basis: 50%;`,
  })}
`;

export const Image = styled.img`
  max-width: 100%;
  margin-left: -4%;

  ${rwd({
    medium: `
      margin-left: 0;
    `,
  })}
`;

export const Content = styled.div`
  ${rwd({
    medium: `
      flex-basis: 50%;
      padding: 0 96px;
    `,
  })}
`;

export const Wrapper = styled.div`
  display: flex;
  margin-bottom: 64px;
  flex-direction: column;

  ${rwd({
    medium: `
      flex-direction: row;   
      align-items: center;
      margin-bottom: 0;

        &:nth-of-type(even) ${Figure} {
          order: 2;
        }
    `,
  })}
`;
