import styled from "styled-components";
import { font, rwd, sizing } from "styles/Mixins";

export const Wrapper = styled.div`
  padding: 0 96px;
  padding-top: 200px;

  ${rwd({
    medium: `
        padding-top: 0;
    `,
  })}

  svg {
    transform: translateX(7px) scale(3.3);

    ${rwd({
      medium: `
        transform: none;
    
      `,
    })}
  }
`;

export const St3 = styled.path`
  fill: #012b48;
`;

export const St4 = styled.path`
  fill: #0d3652;
`;

export const St5 = styled.path`
  fill: #194969;
`;

export const Legend = styled.ul`
  ${sizing("100%")}
  display: flex;
  justify-content: center;
  flex-direction: column;
  z-index: 1000;
`;

export const LegendItem = styled.li`
  color: white;
  ${font("BrownLLWeb", "21px", 600)}
`;
