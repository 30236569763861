import Heading from "components/Heading";
import styled from "styled-components";
import { circle, position, rwd, sizing } from "styles/Mixins";
import { ReactComponent as Play } from "../../assets/play.svg";

export const Wrapper = styled.section`
  background: ${({ theme }) => theme.colors.primaryBlue};
  width: 100%;
  position: relative;
  padding: 0 34px;
  padding-top: ${((970 + 372 + 80) / 1920) * 100}vw;
  padding-bottom: 54px;
  overflow: hidden;
  min-height: 100vh;

  ${rwd({
    small: `
      padding: 0 ${(160 / 1920) * 100}vw;
      padding-top: ${((970 + 372 + 120) / 1920) * 100}vw;
    `,
    medium: `
      padding-top: ${((970 + 1272) / 1920) * 100}vw;
    `,
    large: `
      padding-top: ${((970 + 672) / 1920) * 100}vw;
    `,
  })}
`;

export const PlayHero = styled(Play)`
  ${sizing("342px")}
  ${position("absolute", {
    top: `${(572 / 1920) * 100}vw`,
    left: `calc(50% + ${(150 / 1920) * 100}vw)`,
  })}
  transform: translateX(-50%);
  ${rwd({
    medium: `
      ${position("absolute", {
        top: `${(372 / 1920) * 100}vw`,
        left: `calc(50% + ${(70 / 1920) * 100}vw)`,
      })}
      ${sizing((930 / 1920) * 100 + "vw")}
      min-width: 586px;
      min-height: 586px;
    `,
  })}
`;

export const Circle = styled.div`
  // background: ${({ theme }) => theme.colors.secondaryBlue};
  ${circle("486px")}
  ${position("absolute", { top: `${(572 / 1920) * 100}vw`, left: "50%" })}
  border-radius: 50%;
  transform: translateX(-50%);
  z-index: 1;

  ${rwd({
    medium: `
      ${position("absolute", { top: `${(372 / 1920) * 100}vw`, left: "50%" })}
      ${circle((970 / 1920) * 100 + "vw")}
      min-width: 586px;
      min-height: 586px;
    `,
  })}
`;

export const InnerCircle = styled.div`
  background: ${({ theme }) => theme.colors.primaryBlue};
  transform: translate(-50%, -50%);
  z-index: 2;
  ${position("absolute", { top: "50%", left: "50%" })}
  ${circle("30px")}
  ${rwd({
    medium: `
      ${circle(`${(35 / 1920) * 100}vw`)}
    `,
  })}
`;

export const StyledHeading = styled(Heading)`
  margin-bottom: 24px;
`;

export const Caption = styled(Heading)`
  ${position("absolute", { top: "140px" })}
  z-index: 2;
  font-size: 60px;

  ${rwd({
    medium: `
       top: ${(276 / 1920) * 100}vw;
      font-size: ${(210 / 1920) * 100}vw;
      line-height: ${(210 / 1920) * 100}vw;
    `,
  })}
`;

export const TextContent = styled.div`
  position: relative;
  z-index: 1;
  padding-top: 240px;

  ${rwd({
    medium: `
      padding-top: 0;
      ${position("absolute", { top: "55vw", left: "15vw" })}
      z-index: 2;
      width: ${(808 / 1920) * 100}vw;
    `,
  })}
`;
