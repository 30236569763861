import Heading from "components/Heading";
import Paragraph from "components/Paragraph";
import React from "react";
import { Header, Wrapper, Container, Body } from "./Section.style";

export interface ISectionProps {
  id?: string;
  title?: string;
  excerpt?: string;
  children?: any;
  color?: "white" | "dark" | "light";
  isHorizontal?: string[];
  isFullWidth?: boolean;
  className?: string;
  innerRef?: any;
  style?: any;
  bodyStyle?: any;
  containerStyle?: any;
}

const Section = ({
  title,
  containerStyle,
  className,
  style,
  bodyStyle,
  excerpt,
  color = "white",
  children,
  innerRef,
  isHorizontal,
  isFullWidth,
  id,
}: ISectionProps) => {
  return (
    <Wrapper
      id={id}
      className={className}
      style={style}
      ref={innerRef}
      color={color}
    >
      <Container
        isFullWidth={isFullWidth}
        style={containerStyle}
        isHorizontal={isHorizontal}
      >
        {(title || excerpt) && (
          <Header>
            {title && <Heading l={3} text={title} />}
            {excerpt && <Paragraph text={excerpt} />}
          </Header>
        )}
        <Body style={bodyStyle}>{children}</Body>
      </Container>
    </Wrapper>
  );
};

export default Section;
