import Section from "components/Section";
import React from "react";
import { useTranslation } from "react-i18next";
import Episode from "../Episode";
import { Wrapper } from "./Episodes.style";

import { ReactComponent as Kunde } from "screens/Landing/assets/kunde375.svg";
import { ReactComponent as Wettbewerb } from "screens/Landing/assets/wettbewerb375.svg";
import { ReactComponent as Strategie } from "screens/Landing/assets/strategie375.svg";
import { ReactComponent as Execution } from "screens/Landing/assets/execution375.svg";

export interface IEpisodesProps {
  children?: any;
}

const Episodes = ({}: IEpisodesProps) => {
  const { t } = useTranslation();

  return (
    <Section
      style={{ paddingTop: "220px" }}
      bodyStyle={{ paddingTop: "80px" }}
      color="light"
      title={t("heading-1")}
      excerpt={t("content-1")}
    >
      <Wrapper>
        <Episode
          icon={<Kunde />}
          title={t("content-1-item-1-heading")}
          excerpt={t("content-1-item-1-content")}
          url="/segment"
        />
        <Episode
          icon={<Wettbewerb />}
          title={t("content-1-item-2-heading")}
          excerpt={t("content-1-item-2-content")}
          url="/wettbewerb"
        />
        <Episode
          icon={<Strategie />}
          title={t("content-1-item-3-heading")}
          excerpt={t("content-1-item-3-content")}
          url="/strategie"
        />
        <Episode
          icon={<Execution />}
          title={t("content-1-item-4-heading")}
          excerpt={t("content-1-item-4-content")}
          url="/umsetzung"
        />
      </Wrapper>
    </Section>
  );
};

export default Episodes;
