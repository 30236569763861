import Section from "components/Section";
import useViewPort from "hooks/useViewport";
import React from "react";
import { useTranslation } from "react-i18next";
import BarChart from "./components/BarChart";

const Digitalization = () => {
  const { width } = useViewPort();

  const isMobile = width < 767;
  const { t } = useTranslation("customer");

  return (
    <Section
      isHorizontal={["45%", "55%"]}
      title={t("heading-4")}
      excerpt={t("content-4")}
    >
      <div style={{ width: "100%", padding: "72px 0" }}>
        <BarChart height={isMobile ? 480 : 560} />
      </div>
    </Section>
  );
};

export default Digitalization;
