import styled from "styled-components";
import { font, rwd } from "styles/Mixins";
import { Wrapper as Heading } from "components/Heading/Heading.style";

export const Wrapper = styled.div`
  width: 100%;
  padding: 100px 34px;
  margin: 0 auto;
  font-family: BrownLLWeb;

  ${Heading} {
    margin-bottom: 32px;
  }

  ${rwd({
    xLarge: `
        max-width: 1400px;
        padding: 0 20px;
        padding-top: 200px;
        padding-bottom: 15%;

        ${Heading} {
          margin-bottom: 48px;
        }

      `,
  })}

  h1 {
    font-size: 48px;
    line-height: 60px;
    white-space: pre-line;

    ${rwd({
      xLarge: `
        font-size: 210px;
        line-height: 210px;
      `,
    })}
  }

  h3 {
    font-size: 25px;
    line-height: 31.5px;

    ${rwd({
      xLarge: `
        font-size: 38px;
        line-height: 48px;
      `,
    })}
  }

  p {
    color: #ffffff;
    ${font("BrownLLWeb", "19px", 400)}
    line-height: 31.5px;
    margin: 0;

    ${rwd({
      xLarge: `
      font-size: 21px;
      line-height: 35px;
    `,
    })}

    /* These are technically the same, but use both */
      overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }

  ${Heading} {
    margin: 24px 0;
  }

  a {
    color: rgb(0, 43, 71);
  }
`;
