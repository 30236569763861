import { Wrapper as Container } from "components/Container/Container.style";
import Section from "components/Section";
import styled from "styled-components";
import { rwd } from "styles/Mixins";

export const Wrapper = styled(Section)`
  max-width: 100%;

  ${Container} {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 32px;

    ${rwd({
      medium: `
        row-gap: 64px;
      `,
    })}
  }
`;
