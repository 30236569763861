import React, { useEffect, useRef, useState } from "react";

import { ReactComponent as StormCircle } from "assets/images/storm-circle.svg";
import {
  Circle,
  Caption,
  Wrapper,
  InnerCircle,
  Particles,
  Particle,
  MarketShares,
  MarketShareItem,
  MarketSharesWrapper,
  MarketSharesRanges,
  MarketRangeItem,
  ShareItemValue,
  ShareItemLabel,
} from "./Hero.style";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Heading from "components/Heading";
import { useTranslation } from "react-i18next";
import Section from "components/Section";
import Radar from "../Radar";
import { RadarSection, Slope } from "../Radar/Radar.style";

export interface IHeroProps {}

const Hero = ({}: IHeroProps) => {
  const [level, setLevel] = useState(0);
  const [isExplosionActive, setIsExplosionActive] = useState(false);
  const circleRef = useRef<HTMLDivElement | null>(null);
  const titleRef = useRef<HTMLHeadingElement | null>(null);
  const sharesRef = useRef<HTMLDivElement | null>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const { t } = useTranslation("competition");

  useEffect(() => {
    let tl: GSAPTimeline;
    ScrollTrigger.matchMedia({
      "(max-width: 580px)": () => {
        tl = gsap.timeline({ paused: true });

        tl.to(
          circleRef.current,
          {
            scrollTrigger: {
              trigger: circleRef.current,
              start: "top top",
              endTrigger: "#radar-section",
              end: "top bottom",
              pin: true,
              pinSpacing: false,
            },
          },
          0
        ).to(
          sharesRef.current,
          {
            scrollTrigger: {
              trigger: sharesRef.current,
              start: "top top-=200",
              endTrigger: "#radar-section",
              end: "top bottom",
              pinSpacing: true,
              pin: true,

              scrub: 1,
              onEnter: () => {
                setLevel(1);
              },
              onLeaveBack: () => {
                setLevel(0);
              },
            },
          },
          0
        );
      },
      "(min-width: 581px)": () => {
        tl = gsap.timeline({ paused: true });

        tl.to(circleRef.current, {
          scrollTrigger: {
            trigger: circleRef.current,
            start: "center center",
            endTrigger: wrapperRef.current,
            end: "bottom+=60",
            pin: true,
          },
        }).to(sharesRef.current, {
          scrollTrigger: {
            trigger: sharesRef.current,
            start: "top top+=200",
            endTrigger: wrapperRef.current,
            end: "bottom+=60",
            pin: true,
            anticipatePin: 1,

            onEnter: () => {
              setLevel(1);
              setTimeout(() => {
                setIsExplosionActive(true);
              }, 500);
            },
            onLeaveBack: () => {
              setLevel(0);
              setIsExplosionActive(false);
            },
          },
        });
      },
    });

    return () => {
      tl.kill();
    };
  }, []);

  return (
    <>
      <Wrapper ref={wrapperRef}>
        <div ref={titleRef}>
          <Caption l={1} text={`this is\njust the\nstart`} />
        </div>
        <Circle ref={circleRef}>
          <Particles isActive={isExplosionActive}>
            {Array.from({ length: 200 }).map(() => (
              <Particle />
            ))}
          </Particles>
          <InnerCircle
            color="#013d5e"
            width={[
              "0",
              (617 / 960) * 100 + "%",
              (702 / 960) * 100 + "%",
              (658 / 960) * 100 + "%",
            ]}
            level={level}
          />
          <InnerCircle
            color="#004d72"
            width={[
              "0",
              (251 / 960) * 100 + "%",
              (257 / 960) * 100 + "%",
              (466 / 960) * 100 + "%",
            ]}
            level={level}
          />
          <InnerCircle
            color="#016d9e"
            width={[
              "0",
              (206 / 960) * 100 + "%",
              (187 / 960) * 100 + "%",
              (301 / 960) * 100 + "%",
            ]}
            level={level}
          />
          <InnerCircle
            color="#057eb5"
            width={[
              "0",
              (114 / 960) * 100 + "%",
              (117 / 960) * 100 + "%",
              (192 / 960) * 100 + "%",
            ]}
            level={level}
          />
          <InnerCircle
            color="#058fce"
            width={[
              "0",
              (114 / 960) * 100 + "%",
              (117 / 960) * 100 + "%",
              (165 / 960) * 100 + "%",
            ]}
            level={level}
          />
        </Circle>
        <MarketSharesWrapper ref={sharesRef}>
          <Heading l={3} text="Marktanteile der Incumbents je Segment" />
          <MarketSharesRanges>
            <MarketRangeItem isActive={level === 1} onClick={() => setLevel(1)}>
              0 bis 500 TEUR
            </MarketRangeItem>
            <MarketRangeItem isActive={level === 2} onClick={() => setLevel(2)}>
              500 bis 5000 TEUR
            </MarketRangeItem>
            <MarketRangeItem isActive={level === 3} onClick={() => setLevel(3)}>
              5000 bis 50.000 TEUR
            </MarketRangeItem>
          </MarketSharesRanges>
          <MarketShares>
            <MarketShareItem>
              <ShareItemValue>
                {level === 1 ? "42%" : level === 2 ? "41%" : "35%"}
              </ShareItemValue>
              <ShareItemLabel>Sparkassen</ShareItemLabel>
            </MarketShareItem>
            <MarketShareItem>
              <ShareItemValue>
                {level === 1 ? "27%" : level === 2 ? "30%" : "24%"}
              </ShareItemValue>
              <ShareItemLabel>Volksbanken</ShareItemLabel>
            </MarketShareItem>
            <MarketShareItem>
              <ShareItemValue>
                {level === 1 ? "17%" : level === 2 ? "11%" : "11%"}
              </ShareItemValue>
              <ShareItemLabel>Commerzbank</ShareItemLabel>
            </MarketShareItem>
            <MarketShareItem>
              <ShareItemValue>
                {level === 1 ? "9%" : level === 2 ? "8%" : "11%"}
              </ShareItemValue>
              <ShareItemLabel>Deutsche Bank</ShareItemLabel>
            </MarketShareItem>
            <MarketShareItem>
              <ShareItemValue>
                {level === 1 ? "5%" : level === 2 ? "5%" : "7%"}
              </ShareItemValue>
              <ShareItemLabel>Unicredit</ShareItemLabel>
            </MarketShareItem>
            <MarketShareItem>
              <ShareItemValue>
                {level === 1 ? "5%" : level === 2 ? "5%" : "6%"}
              </ShareItemValue>
              <ShareItemLabel>Postbank</ShareItemLabel>
            </MarketShareItem>
          </MarketShares>
        </MarketSharesWrapper>
      </Wrapper>
      <RadarSection
        id="radar-section"
        color="light"
        title={t("hero-paragraph-heading")}
        excerpt={t("hero-paragraph-content")}
        style={{
          zIndex: 10,
          position: "relative",
          paddingTop: 0,
        }}
      ></RadarSection>
      <Section
        color="light"
        style={{
          paddingBottom: 0,
          marginBottom: "-1px",
        }}
        containerStyle={{
          width: "100%",
          maxWidth: "100%",
          padding: 0,
        }}
        bodyStyle={{
          position: "relative",
          paddingTop: (1630 / 1920) * 100 + "vw",
          marginTop: "80px",
        }}
      >
        <Radar />
        <Slope />
      </Section>
    </>
  );
};

export default Hero;
