import Heading from "components/Heading";
import React, { FC, useRef, useState } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useViewPort from "hooks/useViewport";

import { Item, Label, List, ReadMore, Wrapper } from "./FeaturesList.style";

export interface IFeaturesListProps {
  label: string;
  items: string[][];
  onCollapse?(): void
}

const FeaturesList: FC<IFeaturesListProps> = ({ label, items, onCollapse }) => {
  const { width } = useViewPort();
  const [isOpen, setIsOpen] = useState(width > 768 ? true : false);
  const headingRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLUListElement>(null);

  const onClickHandler = () => {
    setIsOpen(!isOpen);
    ScrollTrigger.refresh();
    window.dispatchEvent(new Event("resize"));
  };

  return (
    <Wrapper>
      <div ref={headingRef}>
        <Heading l={3} text={label} />
      </div>
      {isOpen && (
        <List ref={listRef}>
          {items.map((item) => (
            <>
              <Label>{item[0]}</Label>
              <Item>{item[1]}</Item>
            </>
          ))}
        </List>
      )}

      <ReadMore onClick={onClickHandler}>
        {isOpen ? "- weniger" : "Mehr erfahren +"}
      </ReadMore>
    </Wrapper>
  );
};

export default FeaturesList;
