import styled, { css } from "styled-components";
import { font, position, rwd } from "styles/Mixins";

export const Wrapper = styled.div`
  position: relative;
  overflow: visible;
`;

export const BlockElement = styled.div`
  display: block;
  position: relative;
  max-width: calc(100% - 68px);
  height: auto;
  overflow: visible;
  margin: 0 auto;

  ${rwd({
    small: `
      max-width: ${(625 / 1920) * 100}%;
    `,
    large: `
      align-items: center;
    `,
  })}
`;

export const Description = styled.div`
  width: 100%;
  padding: 0 34px;
  color: ${(props) => props.theme.colors.white};
  z-index: 2;
  font-size: 16px;

  ${rwd({
    small: `
      width: 750px;
    `,
    medium: `
      width: 970px;
    `,
    large: `
      ${position("absolute", { left: "40px", top: 0 })}
      
      width: 50%;
    `,
    xLarge: `
      ${position("absolute", { left: "0px", top: "80px" })}
      width: 50%;
    `,
    xXLarge: `
      ${position("absolute", { left: "240px", top: "160px" })}
      width: 34%;
    `,
  })}
`;

export const Legend = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 0px;

  width: 100%;
  max-width: calc(100% - 40px);
  margin: 30px auto;
  margin-bottom: 60px;

  ${rwd({
    medium: `
      position: absolute;
      top: 0;
      right: 16%;
      max-width: ${(452 / 1920) * 100}%;
      margin-bottom: 0;
    `,
  })}
`;

export const LegendItem = styled.li<{ isActive: boolean }>`
  flex: 1;
  display: flex;
  color: ${(props) => props.theme.colors.white};
  ${font("BrownLLWeb", "16px", 600)}
  text-align: center;
  margin: 0 5px;
  cursor: pointer;
  white-space: nowrap;
  border: 1px solid ${(props) => props.theme.colors.white};
  justify-content: center;
  padding: 7px 0;

  &:hover {
    opacity: 1;
  }

  ${rwd({
    large: `
        ${font("BrownLLWeb", "18px", 600)}
    `,
  })}

  ${(props) =>
    props.isActive &&
    css`
      background-color: ${(props) => props.theme.colors.white};
      color: ${(props) => props.theme.colors.primaryBlue};
    `}
`;

export const Label = styled.text`
  line-height: 44px;
  ${font("BrownLLWeb", "32px", 400)}
  font: {
    size: 32px;
    weight: 400;
  }

  @media screen and (max-width: 768px) {
    fill: ${(props) => props.theme.colors.white} !important;
  }
`;

export const Value = styled.text`
  line-height: 44px;
  ${font("BrownLLWeb", "38px", 600)}
  font: {
    size: 38px;
    weight: 600;
  }

  @media screen and (max-width: 768px) {
    fill: ${(props) => props.theme.colors.white} !important;
    ${font("BrownLLWeb", "48px", 500)}
  }
`;
