import styled  from "styled-components";
import slope from "assets/fragments/stuck-in-the-middle-slope.svg";
import { position, rwd } from "styles/Mixins";
import {default as HeadingComponent} from "components/Heading";


export const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.primaryBlue};
  background-image: url(${slope});
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: 0 -1px;
  padding-top: ${(760 / 1920) * 100}vw;
  position: relative;
`;

export const Heading = styled(HeadingComponent)`
  ${position("absolute", { top: "-32px", left: "50%" })}
  transform: translateX(-50%);
  text-align: center;

  ${rwd({
    medium: `
      ${position("absolute", { top: 0, left: "50%" })}
    `,
  })}
`;