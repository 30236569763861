import Section from "components/Section";
import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Binocular } from "../../assets/binocular.svg";
import { ReactComponent as SelectCircle } from "../../assets/select-circle.svg";
import { ReactComponent as Conversation } from "../../assets/conversation.svg";
import { Item, Wrapper, Heading } from "./Transformation.style";

import Paragraph from "components/Paragraph";

export interface ITransformationProps {
  children?: any;
}

const Transformation = ({}: ITransformationProps) => {
  const { t } = useTranslation();

  return (
    <Section color="dark" title={t("heading-4")}>
      <Wrapper>
        <Item>
          <Binocular />
          <Heading l={3} text={t("content-4-item-1-heading")} />
          <Paragraph text={t("content-4-item-1-content")} />
        </Item>
        <Item>
          <SelectCircle />
          <Heading l={3} text={t("content-4-item-2-heading")} />
          <Paragraph text={t("content-4-item-2-content")} />
        </Item>
        <Item>
          <Conversation />
          <Heading l={3} text={t("content-4-item-3-heading")} />
          <Paragraph text={t("content-4-item-3-content")} />
        </Item>
      </Wrapper>
    </Section>
  );
};

export default Transformation;
