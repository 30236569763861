import Main from "layouts/Main";
import React from "react";
import Hero from "./components/Hero";
import Overview from "./components/Overview";
import { Wrapper } from "./Strategie.style";

export interface IStrategieProps {
  children: any;
}

const Strategie = ({}: IStrategieProps) => (
  <Main>
    {() => (
      <Wrapper>
        <Hero />
        <Overview />
      </Wrapper>
    )}
  </Main>
);

export default Strategie;
