import React from "react";
import { Wrapper } from "./Button.style";

export interface IButtonProps {
  text: string;
  className?: string;
  type?: string;
  href?: string;
}

const Button = ({ text, type, className, href }: IButtonProps) => {
  const el = href ? "a" : "button";

  const props = href ? { href, className } : { className };

  return (
    <Wrapper as={el} {...props}>
      {text}
    </Wrapper>
  );
};

export default Button;
