import React, { FC } from "react";
import { Wrapper } from "./Paragraph.style";

export interface IParagraphProps {
  text: string;
  className?: string;
  style?: any;
  isHtml?: boolean;
}

const Paragraph = ({
  text,
  isHtml,
  className,
  style,
}: IParagraphProps): JSX.Element =>
  isHtml ? (
    <Wrapper
      dangerouslySetInnerHTML={isHtml ? { __html: text } : undefined}
      style={style}
      className={className}
    />
  ) : (
    <Wrapper style={style} className={className}>
      {text}
    </Wrapper>
  );

export default Paragraph;
