import { css } from "styled-components";

export const flexCentered = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const flexCenteredX = css`
  display: flex;
  justify-content: center;
`;

export const flexCenteredY = css`
  display: flex;
  align-items: center;
`;
