import currency from "currency.js";
import React, { FC } from "react";
import FeaturesList from "../FeaturesList";
import MobileIcon from "./MobileIcon";
import ScrollTrigger from 'gsap/ScrollTrigger'
import { AverageValue, Icon, IconWrapper, ProfileTitle, ValueLabel, ValuesWrapper, Wrapper } from "./Profile.style";

export interface ISMECustomerProfileProps {
  label: string;
  icon: any;
  micon: any;
  sale: number;
  employees: number;
  featuresLabel: string;
  features: string[][];
}

const SMECustomerProfile: FC<ISMECustomerProfileProps> = ({
  label,
  icon,
  micon,
  sale,
  employees,
  featuresLabel,
  features,
}) => {
  return (
    <Wrapper>
      <ProfileTitle l={3} text={label} />

      <MobileIcon
        icon={micon}
        employees={employees}
        income={sale}
      />

      <IconWrapper>
        <Icon src={icon} />
      </IconWrapper>

      <ValuesWrapper>
        <ValueLabel>Umsatz in EUR</ValueLabel>
        <AverageValue>
          {currency(sale, {
            separator: ".",
            symbol: "",
            precision: 0,
          }).format()}
        </AverageValue>
        <ValueLabel>Angestellte</ValueLabel>
        <AverageValue>{employees}</AverageValue>
      </ValuesWrapper>

      <FeaturesList onCollapse={() => ScrollTrigger.refresh()} label={featuresLabel} items={features} />
    </Wrapper>
  );
};

export default SMECustomerProfile;
