import Paragraph from "components/Paragraph";
import Section from "components/Section";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ContentCircle,
  Mockup,
  Wrapper,
  Button,
  Heading,
} from "./Project.style";

import mockup from "assets/images/mockup.png";

export interface IProjectProps {
  children?: any;
}

const Project = ({}: IProjectProps) => {
  const { t } = useTranslation();
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const circleRef = useRef<HTMLDivElement | null>(null);
  const [mousePos, setMousePos] = useState([0, 0]);
  useEffect(() => {
    const cb = (e: MouseEvent) => {
      const { clientX, clientY } = e;
      const el = circleRef.current;

      if (!el) return;

      const { x, y } = el.getBoundingClientRect();

      setMousePos([x - clientX, y + clientY]);
    };

    wrapperRef.current?.addEventListener("mousemove", cb);

    return () => {
      wrapperRef.current?.removeEventListener("mousemove", cb);
    };
  }, []);

  return (
    <Wrapper ref={wrapperRef}>
      <ContentCircle
        ref={circleRef}
        style={{
          // @ts-ignore
          "--x": mousePos[0] / 10 + "px",
          // @ts-ignore
          "--y": mousePos[1] / 14 + "px",
        }}
      >
        <Mockup
          style={{
            // @ts-ignore
            "--x": (mousePos[0] / 30) * -1 + "px",
            // @ts-ignore
            "--y": (mousePos[1] / 42) * -1 + "px",
          }}
          src={mockup}
        />
        <Heading l={3} text={t("heading-3")} />
        <Paragraph text={t("content-3")} />

        <Button
          href="ma&#105;lto&#58;%6B%6&#68;uba&#110;king%4&#48;ze%62&#45;applie%6&#52;&#46;%&#54;3&#111;m?subject=kmubanking.digital%20%7C%20Pers%C3%B6nliche%20Demo&body=Liebes%20kmubanking-Team%2C%0D%0A%0D%0Agern%20m%C3%B6chte%20ich%20mehr%20erfahren%2C%20vor%20allem%20sehen%2C%20vom%20digitalen%20KMU-Banking%20und%20freue%20mich%20%C3%BCber%20eine%20pers%C3%B6nliche%20Demo%20des%20Prototyps%20und%20der%20weiterf%C3%BChrenden%20Gedanken.%20Meine%20Signatur%20habe%20ich%20dieser%20Mail%20unten%20angef%C3%BCgt.%20Ich%20freue%20mich%20%C3%BCber%20eine%20Kontaktaufnahme.%0D%0A%0D%0AMit%20freundlichen%20Gr%C3%BC%C3%9Fen"
          text={t("content-3-button-1")}
        />
      </ContentCircle>
    </Wrapper>
  );
};

export default Project;
