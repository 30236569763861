import styled from "styled-components";
import { font, rwd } from "styles/Mixins";

export const Text = styled.text`
  ${font('BrownLLWeb', '16px', 600)}
`;

export const Label = styled.text`
  ${font("BrownLLWeb", "16px", 600)}
  writing-mode: vertical-lr;

  ${rwd({
    medium: `
      writing-mode: initial;
    `,
  })}

`;