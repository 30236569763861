import useViewPort from "hooks/useViewport";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import { ReactComponent as Logo } from "assets/images/kmu-logo.svg";
import { ReactComponent as PlayVideo } from "assets/images/play-video.svg";
import {
  Footer,
  FooterLinkItem,
  FooterLinks,
  Link,
  LinkItem,
  Links,
  LogoLink,
  MobileNavs,
  Social,
  TopWrapper,
  Trailer,
  TrailerLabel,
  Wrapper,
} from "./TopBar.style";
import { ReactComponent as LinkedIn } from "assets/icons/linkedin.svg";
import Hamburger from "components/Hamburger";
import { useTranslation } from "react-i18next";
import { enablePageScroll, disablePageScroll } from "scroll-lock";

export interface ITopBarProps {
  setIsPlaying(state: boolean): void;
}

const Navigation = ({ location }: any) => (
  <>
    <LinkItem isActive={location.pathname.includes("segment")}>
      <Link to="/segment">Das Segment</Link>
    </LinkItem>
    <LinkItem isActive={location.pathname.includes("wettbewerb")}>
      <Link to="/wettbewerb">Der Wettbewerb</Link>
    </LinkItem>
    <LinkItem isActive={location.pathname.includes("strategie")}>
      <Link to="/strategie">Die Strategie</Link>
    </LinkItem>
    <LinkItem isActive={location.pathname.includes("execution")}>
      <Link to="/umsetzung">Die Umsetzung</Link>
    </LinkItem>
  </>
);

const TopBar = ({ setIsPlaying }: ITopBarProps) => {
  const [scrollDir, setScrollDir] = useState<string>("bottom");
  const location = useLocation();
  const { width } = useViewPort();
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const lastScrollTopRef = useRef(
    window.pageYOffset || document.documentElement.scrollTop
  );

  useEffect(() => {
    if (isOpen) {
      disablePageScroll();
    } else {
      enablePageScroll();
    }
  }, [isOpen]);

  useEffect(() => {
    const cb = () => {
      if (isOpen) return;
      const st = window.pageYOffset || document.documentElement.scrollTop;
      if (st > lastScrollTopRef.current) {
        setScrollDir("bottom");
      } else {
        setScrollDir("top");
      }

      lastScrollTopRef.current = st <= 0 ? 0 : st;
    };

    window.addEventListener("scroll", cb, false);
    return () => {
      window.removeEventListener("scroll", cb, false);
    };
  }, [isOpen]);

  return (
    <Wrapper isOpen={isOpen} isAttached={scrollDir !== "bottom"}>
      <TopWrapper>
        <LogoLink to="/">
          <Logo width={width < 768 ? 140 : 180} style={{ fill: "#fff" }} />
        </LogoLink>
        <Links>
          <Navigation location={location} />
        </Links>

        <Trailer onClick={() => setIsPlaying(true)}>
          <TrailerLabel>Video</TrailerLabel>
          <PlayVideo height="32" />
        </Trailer>
        <Hamburger isToggled={isOpen} onClick={() => setIsOpen(!isOpen)} />
      </TopWrapper>
      <MobileNavs isOpen={isOpen}>
        <Navigation location={location} />
      </MobileNavs>
      <Footer>
        <Social>
          <a href="https://www.linkedin.com/company/zebapplied" target="_blank">
            <LinkedIn width="30" />
          </a>
        </Social>

        <FooterLinks>
          <FooterLinkItem>
            <Link to="/privacy">{t("footer-privacy")}</Link>
          </FooterLinkItem>
          <FooterLinkItem>
            <Link to="/imprint">{t("footer-imprint")}</Link>
          </FooterLinkItem>
        </FooterLinks>
      </Footer>
    </Wrapper>
  );
};

export default TopBar;
