import Heading from "components/Heading";
import Paragraph from "components/Paragraph";
import Main from "layouts/Main";
import React from "react";
import { Wrapper } from "./Imprint.style";

export interface IImprintProps {
  children: any;
}

const Imprint = ({}: IImprintProps) => (
  <Main>
    {() => (
      <Wrapper>
        <Heading l={1} text="Impressum" />
        <Heading l={3} text="Unternehmensangaben" />
        <Paragraph
          text={`
             <strong>zeb.applied gmbh</strong>
          <br />
          Hammer Straße 165
          <br />
          48153 Münster
          <br />
        `}
          isHtml
        />

        <Paragraph
          isHtml
          text={`
          Telefon: +49-251-97128-0
          <br />
          E-Mail:
          <a href="mailto:hello@zeb-applied.com">hello@zeb-applied.com</a>
        `}
        />
        <Paragraph
          isHtml
          text={`
          Geschäftsführer und inhaltlich verantwortlich:
          <br />
          Dr. Eddie Dubiel
        `}
        />
        <Paragraph
          isHtml
          text={`
          Handelsregister HRB 17768
          <br />
          Amtsgericht Münster
        `}
        />
        <Paragraph isHtml text={`Ust-ID: DE322525221`} />
      </Wrapper>
    )}
  </Main>
);

export default Imprint;
