import { createGlobalStyle } from "styled-components";

import BrownLLWebLight from "assets/fonts/BrownLL/BrownLLWeb-Light.woff";
import BrownLLWebLightWoff2 from "assets/fonts/BrownLL/BrownLLWeb-Light.woff2";
import BrownLLWebRegularWoff from "assets/fonts/BrownLL/BrownLLWeb-Regular.woff";
import BrownLLWebRegularWoff2 from "assets/fonts/BrownLL/BrownLLWeb-Regular.woff2";
import BrownLLWebBoldWoff from "assets/fonts/BrownLL/BrownLLWeb-Bold.woff";
import BrownLLWebBoldWoff2 from "assets/fonts/BrownLL/BrownLLWeb-Bold.woff2";

export default createGlobalStyle`
  * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-display: fallback;
  }

  body, html {
    margin: 0;
    font-family: 'BrownLLWeb';
    font-weight: 400;
    font-display: fallback;
  }

  @font-face {
    font-family: 'BrownLLWeb';
    font-weight: 300;
    src: url(${BrownLLWebLight}) format('woff');
    font-display: fallback;
  }

  @font-face {
    font-family: 'BrownLLWeb';
    font-weight: 300;
    src: url(${BrownLLWebLightWoff2}) format('woff2');
    font-display: fallback;
  }

  @font-face {
    font-family: 'BrownLLWeb';
    font-weight: 400;
    src: url(${BrownLLWebRegularWoff}) format('woff');
    font-display: fallback;
  }

  @font-face {
    font-family: 'BrownLLWeb';
    font-weight: 400;
    src: url(${BrownLLWebRegularWoff2}) format('woff2');
    font-display: fallback;
  }

  @font-face {
    font-family: 'BrownLLWeb';
    font-weight: 500;
    src: url(${BrownLLWebBoldWoff}) format('woff');
    font-display: fallback;
  }

  @font-face {
    font-family: 'BrownLLWeb';
    font-weight: 500;
    src: url(${BrownLLWebBoldWoff2}) format('woff2');
    font-display: fallback;
  }

`;
