import React, { FC } from "react";
import { Wrapper, Bar } from "./Hamburger.style";

export interface IHamburgerProps {
  onClick(): void;
  isToggled: boolean;
}

const Hamburger: FC<IHamburgerProps> = ({ isToggled, onClick }) => {

  return (
    <Wrapper onClick={onClick}>
      <Bar isActive={isToggled} />
      <Bar isActive={isToggled} />
    </Wrapper>
  );
};

export default Hamburger;
