import { font, rwd } from "styles/Mixins";
import styled from "styled-components";

export const Wrapper = styled.p`
  color: #ffffff;
  ${font('BrownLLWeb', '19px', 400)}
  line-height: 31.5px;
  margin: 0;

  ${rwd({
    xLarge: `
      font-size: 21px;
      line-height: 35px;
    `,
  })}
`;
