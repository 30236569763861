import React, { useEffect, useRef } from "react";
import {
  Section,
  Tablet,
  TabletWrapper,
  TooltipFour,
  TooltipOne,
  TooltipThree,
  TooltipTwo,
  Wrapper,
} from "./Presentation.style";

import tablet from "assets/images/tablet.png";
import pin1 from "../../assets/pin_1.png";
import pin2 from "../../assets/pin_2.png";
import pin3 from "../../assets/pin_3.png";
import pin4 from "../../assets/pin_4.png";

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

export interface IPresentationProps {}

gsap.registerPlugin(ScrollTrigger);

const Presentation = ({}: IPresentationProps) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const tabletRef = useRef<HTMLImageElement | null>(null);
  const tabletWrapperRef = useRef<HTMLImageElement | null>(null);
  const pinOneRef = useRef<HTMLImageElement | null>(null);
  const pinTwoRef = useRef<HTMLImageElement | null>(null);
  const pinThreeRef = useRef<HTMLImageElement | null>(null);
  const pinFourthRef = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    const tl = gsap.timeline({ paused: true });

    ScrollTrigger.matchMedia({
      "(min-width: 780px)": () => {
        tl.to(pinOneRef.current, {
          scrollTrigger: {
            trigger: pinOneRef.current,
            pin: true,
            start: "top center-=30%",
            endTrigger: "#overview",
            end: "top bottom",
            invalidateOnRefresh: true,
            onEnter: () => {
              // @ts-ignore
              pinOneRef.current.style.opacity = 1;
            },
            onEnterBack: () => {
              // @ts-ignore
              pinOneRef.current.style.opacity = 1;
            },
            onLeave: () => {
              // @ts-ignore
              pinOneRef.current.style.opacity = 0;
            },
            onLeaveBack: () => {
              // @ts-ignore
              pinOneRef.current.style.opacity = 0;
            },
          },
        })
          .to(pinTwoRef.current, {
            immediateRender: false,
            scrollTrigger: {
              trigger: pinTwoRef.current,
              pin: true,
              start: "top center",
              endTrigger: "#overview",
              end: "top bottom",
              invalidateOnRefresh: true,
              onEnter: () => {
                // @ts-ignore
                pinTwoRef.current.style.opacity = 1;
              },
              onEnterBack: () => {
                // @ts-ignore
                pinTwoRef.current.style.opacity = 1;
              },
              onLeave: () => {
                // @ts-ignore
                pinTwoRef.current.style.opacity = 0;
              },
              onLeaveBack: () => {
                // @ts-ignore
                pinTwoRef.current.style.opacity = 0;
              },
            },
          })

          .to(pinThreeRef.current, {
            immediateRender: false,
            scrollTrigger: {
              trigger: pinThreeRef.current,
              pin: true,
              start: "top center+=200",
              endTrigger: "#overview",
              end: "top bottom",
              invalidateOnRefresh: true,
              onEnter: () => {
                // @ts-ignore
                pinThreeRef.current.style.opacity = 1;
              },
              onEnterBack: () => {
                // @ts-ignore
                pinThreeRef.current.style.opacity = 1;
              },
              onLeave: () => {
                // @ts-ignore
                pinThreeRef.current.style.opacity = 0;
              },
              onLeaveBack: () => {
                // @ts-ignore
                pinThreeRef.current.style.opacity = 0;
              },
            },
          })
          .to(pinFourthRef.current, {
            immediateRender: false,
            scrollTrigger: {
              trigger: pinFourthRef.current,
              pin: true,
              start: "top center+=260",
              endTrigger: "#overview",
              end: "top bottom",
              invalidateOnRefresh: true,
              onEnter: () => {
                // @ts-ignore
                pinFourthRef.current.style.opacity = 1;
              },
              onEnterBack: () => {
                // @ts-ignore
                pinFourthRef.current.style.opacity = 1;
              },
              onLeave: () => {
                // @ts-ignore
                pinFourthRef.current.style.opacity = 0;
              },
              onLeaveBack: () => {
                // @ts-ignore
                pinFourthRef.current.style.opacity = 0;
              },
            },
          });
      },
    });

    return () => {
      tl.kill();
    };
  }, []);

  useEffect(() => {
    window.dispatchEvent(new Event("resize"));
  }, []);

  return (
    <Section color="dark">
      <Wrapper ref={wrapperRef}>
        <TabletWrapper ref={tabletWrapperRef}>
          <Tablet ref={tabletRef} src={tablet} />
        </TabletWrapper>
        <TooltipOne ref={pinOneRef} src={pin1} />
        <TooltipTwo ref={pinTwoRef} src={pin2} />
        <TooltipThree ref={pinThreeRef} src={pin3} />
        <TooltipFour ref={pinFourthRef} src={pin4} />
      </Wrapper>
    </Section>
  );
};

export default Presentation;
