import { css } from "styled-components";
import { position } from "./Mixins";

export const heroPosition = css`
  ${position("absolute", { top: `${(272 / 1920) * 100}vw`, left: "50%" })}
`;

export const heroCaptionPosition = css`
  top: ${(176 / 1920) * 100}vw;
`;
