import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Competition from "screens/Competition";
import Execution from "screens/Execution";
import Imprint from "screens/Imprint";
import Landing from "screens/Landing";
import Privacy from "screens/Privacy";
import Strategie from "screens/Strategie";
import { ThemeProvider } from "styled-components";
import Theme from "styles/Theme";
import GlobalStyle from "./GlobalStyle";
import Segment from "./screens/Segment";

function App() {
  return (
    <ThemeProvider theme={Theme}>
      <GlobalStyle />
      <div className="App">
        <Router>
          <Route exact path="/" component={Landing} />
          <Route exact path="/segment" component={Segment} />
          <Route exact path="/wettbewerb" component={Competition} />
          <Route exact path="/strategie" component={Strategie} />
          <Route exact path="/umsetzung" component={Execution} />
          <Route exact path="/privacy" component={Privacy} />
          <Route exact path="/imprint" component={Imprint} />
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
