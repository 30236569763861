import Main from "layouts/Main";
import React from "react";
import Hero from "./components/Hero";
import Presentation from "./components/Presentation";
import Overview from "./components/Overview";

export interface IExecutionProps {
  children: any;
}

const Execution = ({}: IExecutionProps) => (
  <Main isOverflow={true}>
    {() => (
      <>
        <Hero />
        <Presentation />
        <Overview />
      </>
    )}
  </Main>
);

export default Execution;
