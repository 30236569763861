import Section from "components/Section";
import Main from "layouts/Main";
import React from "react";
import { useTranslation } from "react-i18next";
import Hero from "./components/Hero";
import Radar from "./components/Radar";
import { Slope } from "./components/Radar/Radar.style";

import chart from "assets/images/dis-graph.png";
import { Chart } from "./Competition.style";

export interface ICompetitionProps {
  children: any;
}

const Competition = ({}: ICompetitionProps) => {
  const { t } = useTranslation("competition");

  return (
    <Main>
      {() => (
        <>
          <Hero />
          <Section
            isHorizontal={["45%", "55%"]}
            title={t("heading-2")}
            excerpt={t("content-2")}
            color="white"
          >
            <div>
              <Chart src={chart} />
            </div>
          </Section>
        </>
      )}
    </Main>
  );
};

export default Competition;
