import React, { FC } from "react";
import { Wrapper } from "./Heading.style";

export interface IHeadingProps {
  l: 1 | 2 | 3 | 4 | 5 | 6;
  text: string;
  className?: string;
}

const Heading: FC<IHeadingProps> = ({ l, text, className }) => {
  const el = `h${l}`;
  return (
    // @ts-ignore
    <Wrapper className={className} as={el} level={l}>
      {text}
    </Wrapper>
  );
};

export default Heading;
