import Section from "components/Section";
import React from "react";
import { useTranslation } from "react-i18next";
import SMETurnover from "./components/SMETurnover";

const EconomicFactor = () => {
  const { t } = useTranslation("customer");
  
  return (
    <Section color="dark" title={t("heading-1")} excerpt={t("content-1")}>
      <SMETurnover />
    </Section>
  );
};

export default EconomicFactor;
