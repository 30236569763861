import Section from "components/Section";
import React from "react";
import OverviewRow from "../OverviewRow";
import { Wrapper } from "./Overview.style";

import execution_1 from "assets/images/execution_1.png";
import execution_2 from "assets/images/execution_2.png";
import execution_3 from "assets/images/execution_3.png";
import execution_4 from "assets/images/execution_4.png";

export interface IOverviewProps {
  // children: any
}

const Overview = ({}: IOverviewProps) => (
  <Wrapper isFullWidth id="overview" color="white">
    <OverviewRow
      img={execution_1}
      heading="Vermarktung heißt Fokussierung"
      content="Im operativen Marktauftritt zeigt sich die Qualität der strategischen Weichenstellungen. Mutige Fokussierung auf spitzere Zielgruppen, klare Trennung von Neukunden- und Bestandskundenwelt (Login) und hohe Anmutung in der UI/UX setzen ein unübersehbares Ausrufezeichen. Nur wer bereits in der Vermarktung die Unternehmen sowie ihre Herausforderungen versteht und in den Mittelpunkt stellt, schafft eine effiziente Neukundenansprache zu attraktiven Customer Acqusition Costs (CAC)."
    />
    <OverviewRow
      img={execution_2}
      heading="Onboarding als Erlebnis statt Conversion-Killer"
      content="Im Onboarding zählt es: wird die Interessentin zu einer echten Kundin? Dabei sollte der Fokus weniger auf dem klassischen (regulatorischen) Kundenbegriff liegen, als vielmehr auf einem modernen User-Verständnis. Niedrigschwellige Einstiege (auch ohne KYC) schaffen, UX für minimale und intuitive Eingaben optimieren, Prozesstransparenz sicherstellen und dabei das Onboarding als fortwährende Aufgabe verstehen - die Grundanforderungen sind klar, doch in der Praxis noch zu selten in Umsetzung."
    />
    <OverviewRow
      img={execution_3}
      heading="Nach dem Login ist vor dem Produkt"
      content="Der Login muss zu einem magischen (und gleichzeitig selbstverständlichen) Moment werden für die KMU. Die intuitive Bedienbarkeit gewährt Zugang zu den Features und Produkten der Bank. Klassische Bankdienstleistungen sind dabei mit Near- und Beyond-Banking-Features nahtlos verzahnt. Datenbasiert wird den KMU die passende Lösung im richtigen Moment und an der richtigen Stelle bereitgestellt - als 'Second Line of Offense' durch die Beraterinnen unterstützt. Hauptaugenmerk ist die Kollaboration aller Beteiligten auf der digitalen Plattform (innerhalb des KMU, zwischen Bank und KMU sowie innerhalb der Bank)."
    />
    <OverviewRow
      img={execution_4}
      heading="Die KMU-Sicht ist nur die halbe Wahrheit"
      content={`Digitales KMU-Banking endet nicht am Kunden-Frontend – „Berater-second" wird als Effektivitätsschub in der Kundenbindung und -durchdringung digital neu gedacht. Die kostbare, weil knappe Ressource Beratungszeit wird auf den maximalen Impact in der Digital-First-Kundenbeziehung optimiert. Vertriebsfernere Bereiche arbeiten digital-vernetzt Hand-in-Hand mit dem Beratungsteam. Die effiziente Kollaboration innerhalb der Bank wird nicht verordnet, sondern durch eine ausgezeichnete, geführte User Experience für ALLE internen Mitarbeiterinnen von allein zur erlebten Selbstverständlichkeit.`}
    />
  </Wrapper>
);

export default Overview;
