import styled, { css } from "styled-components";
import { circle, font, rwd } from "styles/Mixins";

export const Grid = styled.div`
  display: grid;
  grid-template-rows: repeat(10, 1fr);
  grid-template-columns: repeat(33, 1fr);
  column-gap: 4px;
  row-gap: 4px;
  margin-top: 60px;

  ${rwd({
    large: `
      column-gap: 14px;
      row-gap: 14px;
    `,
  })}
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 32px 0;

  flex-direction: column;

  ${rwd({
    medium: `
    flex-direction: row;
    justify-content: space-between;
     align-items: center;
    `,
  })}
`;

export const Legend = styled.ul`
  margin: 0 -4px;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;

  ${rwd({
    large: `
      flex-direction: row;
      margin: 0 -14px;
    `,
  })}
`;

export const LegendItem = styled.li`
  display: flex;
  align-items: center;
  margin: 4px 0;
  color: ${({ theme }) => theme.colors.white};
  ${font("BrownLLWeb", "19px", 400)}

  ${rwd({
    large: `
      margin: 0 14px;
    `,
  })}

  span {
    padding-left: 6px;
  }
`;

export const Point = styled.div<{ isActive?: boolean; size: number }>`
  display: block;
  ${(props) => circle(props.size + "px")}
  background-color: ${(props) => props.theme.colors.white};

  ${(props) =>
    props.isActive &&
    css`
      background-color: ${(props) => props.theme.colors.primaryBlue};
    `}
`;
