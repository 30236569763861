import styled from "styled-components";
import { font, rwd } from "styles/Mixins";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.colors.white};
`;

export const LinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &:after {
    margin-top: 4px;
    display: block;
    content: "";
    width: 100%;
    height: 1px;
    background-color: ${props => props.theme.colors.white};
    transform: translateX(-101%);
    transition: transform 0.3s ease-in-out;
  }

  &:hover {
    opacity: 1;
  }

  &:hover:after {
    transform: translateX(0);
  }
`;

export const Link = styled.a`
  color: inherit;
  text-decoration: none;
`;

export const Chapter = styled.div``;

export const ChapterSubtitle = styled.div`
  ${font("BrownLLWeb", "20px", 300)}
  ${rwd({
    large: `
      ${font("BrownLLWeb", "25px", 300)}
    `,
  })}
`;

export const ChapterLabel = styled.div`
  ${font("BrownLLWeb", "32px", 400)}
  ${rwd({
    large: `
        ${font("BrownLLWeb", "50px", 400)}
    `,
  })}
`;
