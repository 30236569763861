import Paragraph from "components/Paragraph";
import Section from "components/Section";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  Button,
  Form,
  Wrapper,
  TextField,
  PrivacyText,
  Plane,
  Heading,
} from "./Newsletter.style";

import plane from "assets/images/paper-plane.png";

export interface INewsletterProps {
  children?: any;
}

const Newsletter = ({}: INewsletterProps) => {
  const { t } = useTranslation();
  return (
    <Wrapper color="white" isHorizontal={["50%", "50%"]}>
      <div>
        <Heading l={3} text={t("newsletter-heading")} />
        <Paragraph text={t("newsletter-content")} />
        <Paragraph
          style={{ marginTop: "32px" }}
          text={t("newsletter-form-content")}
        />
        <Form
          className={"js-cm-form"}
          id="subForm"
          action="https://www.createsend.com/t/subscribeerror?description="
          method="post"
          data-id="92D4C54F0FEC16E5ADC2B1904DE9ED1AA0EC784D10249D47BB801B2C5D4B8BB4898F051D3A7DA4F62C034122B457337515F0F83982965AE097E8C78C670D0E32"
        >
          <TextField
            className={"js-cm-email-input qa-input-email"}
            placeholder={t("newsletter-input-placeholder")}
            id={"fieldEmail"}
            name={"cm-jitjitr-jitjitr"}
          />
          <Button text={t("newsletter-form-send")} />
        </Form>
        <PrivacyText>
          Ich habe die{" "}
          <Link to="/privacy">Ausführungen zur Datenverarbeitung</Link> im
          Rahmen der Newsletteranmeldung gelesen und willige darin mit dem
          Absenden meiner Daten entsprechend ein.
        </PrivacyText>
      </div>
      <Plane src={plane} />
    </Wrapper>
  );
};

export default Newsletter;
