import Heading from "components/Heading";
import Paragraph from "components/Paragraph";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  Content,
  Gradient,
  GradientWrapper,
  Point,
  PointsWrapper,
  Wrapper,
} from "./Radar.style";

export interface IRadarProps {
  children?: any;
}

const Radar = ({}: IRadarProps) => {
  const { t } = useTranslation("competition");

  return (
    <>
      <GradientWrapper>
        <Gradient></Gradient>
      </GradientWrapper>
      <Wrapper>
        <Content>
          <Heading l={3} text={t("heading-1")} />
          <Paragraph text={t("content-1")} />
        </Content>
        <PointsWrapper>
          {Array.from({ length: 100 }).map(() => (
            <Point />
          ))}
        </PointsWrapper>
      </Wrapper>
    </>
  );
};

export default Radar;
