import styled from "styled-components";
import { font, rwd } from "styles/Mixins";

export const Wrapper = styled.svg`
  display: block;

  ${rwd({
    medium: `
      display: none;
    `,
  })}
`;

export const Border = styled.circle`
  stroke: ${(props) => props.theme.colors.primaryBlue};
`;

export const Label = styled.text`
  ${font("BrownLLWeb", "16px", 400)}
  line-height: 2.19;
  fill: ${(props) => props.theme.colors.white};
`;

export const Value = styled.text`
  ${font("BrownLLWeb", "25px", 400)}
  line-height: 1.24;
  fill: ${(props) => props.theme.colors.primaryBlue};
`;
