import Button from "components/Button";
import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Arrow } from "assets/icons/arrow_lightblue.svg";
import {
  BackgroundVideo,
  Bild,
  Caption,
  Circle,
  DemoWrapper,
  Discover,
  Label,
  PlayTrailerWrapper,
  Widget,
  WidgetLabel,
  Wrapper,
  ScrollDown,
  DiscoverLink,
} from "./Hero.style";

import bild from "assets/images/bild.png";

export interface IHeroProps {
  play(): void;
}

const Hero = ({ play }: IHeroProps) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <BackgroundVideo autoPlay muted loop playsInline>
        <source src="/landing-teaser.mp4" type="video/mp4" />
      </BackgroundVideo>
      <Caption l={1} text={`KMU-Banking \n der Zukunft`} />

      <Circle>
        <PlayTrailerWrapper onClick={play}>
          <svg width="60" height="60">
            <g>
              <circle cx="30" cy="30" r="30" fill="#fff" />
              <path d="M20.226 44.794l25-13.794-25-13.794z" fill="#1da1f2" />
            </g>
          </svg>

          <Label>{t("cta-video")}</Label>
        </PlayTrailerWrapper>
        <DemoWrapper>
          <Label>{t("cta-demo")}</Label>
          <Button
            text={t("cta-demo-button")}
            href="ma&#105;lto&#58;%6B%6&#68;uba&#110;king%4&#48;ze%62&#45;applie%6&#52;&#46;%&#54;3&#111;m?subject=kmubanking.digital%20%7C%20Pers%C3%B6nliche%20Demo&body=Liebes%20kmubanking-Team%2C%0D%0A%0D%0Agern%20m%C3%B6chte%20ich%20mehr%20erfahren%2C%20vor%20allem%20sehen%2C%20vom%20digitalen%20KMU-Banking%20und%20freue%20mich%20%C3%BCber%20eine%20pers%C3%B6nliche%20Demo%20des%20Prototyps%20und%20der%20weiterf%C3%BChrenden%20Gedanken.%20Meine%20Signatur%20habe%20ich%20dieser%20Mail%20unten%20angef%C3%BCgt.%20Ich%20freue%20mich%20%C3%BCber%20eine%20Kontaktaufnahme.%0D%0A%0D%0AMit%20freundlichen%20Gr%C3%BC%C3%9Fen"
          />
        </DemoWrapper>

        <ScrollDown />
      </Circle>

      <Widget>
        <Bild src={bild} />
        <WidgetLabel>Firmenkundenstudie 9.0</WidgetLabel>
        <Discover>
          <DiscoverLink
            href="https://zeb-consulting.com/de-DE/publikationen/firmenkundenstudie-9.0"
            target="_blank"
          >
            entdecken <Arrow stroke="#fff" />
          </DiscoverLink>
        </Discover>
      </Widget>
    </Wrapper>
  );
};

export default Hero;
