import ButtonComponent from "components/Button";
import styled from "styled-components";
import { circle, font, position, rwd } from "styles/Mixins";
import HeadingComponent from "components/Heading";

export const Wrapper = styled.div`
  background: ${(props) => props.theme.colors.secondaryBlue};
  display: flex;
  justify-content: flex-end;
  padding: 0 ${(260 / 1920) * 100}vw;

  padding-top: 130vw;
  position: relative;

  ${rwd({
    medium: `
      position: unset;
      overflow: hidden;
      padding-top: calc(144px + 8vw);
      padding-bottom: calc(144px + 32vw);
    `,
  })}
`;

export const Heading = styled(HeadingComponent)`
  padding-bottom: 24px;
`;

export const ContentCircle = styled.div`
  background: ${(props) => props.theme.colors.primaryBlue};
  ${circle("180vw")}
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 32px;
  position: absolute;
  top: 0;
  left: -40vw;
  padding-left: calc(40vw + 34px);
  padding-right: calc(40vw + 34px);
  margin-top: -220px;

  ${rwd({
    medium: `
      position: absolute;
      top: unset;
      left: unset;
      margin-top: -520px;
      ${circle((969 / 1920) * 100 + "vw")}
      padding-left: ${(160 / 1920) * 100}vw;
      padding-right: ${(120 / 1920) * 100}vw;
    `,
  })}

  &:before {
    ${position("absolute", { top: "-8vw", left: "-5vw" })}
    ${circle((1280 / 1920) * 100 + "vw")}
    display: none;
    content: "";
    border: 1px solid ${(props) => props.theme.colors.primaryBlue};
    transition: transform cubic-bezier(0.5, 1, 0.89, 1) 0.3s;
    transform: translate(var(--x), var(--y));

    ${rwd({
      medium: `
       display: block;
      `,
    })}
  }
`;

export const Mockup = styled.img`
  max-width: ${(700 / 1920) * 100}vw;
  ${position("absolute", { top: "-80px", left: "-60%" })}
  transform: translate(var(--x), var(--y));
  transition: transform cubic-bezier(0.5, 1, 0.89, 1) 0.3s;
`;

export const Button = styled(ButtonComponent)`
  ${font("BrownLLWeb", "20px", 600)}
  background: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.secondaryBlue};
  height: 48px;
  margin-top: 24px;
  z-index: 1;
`;
