import { Wrapper as HeadingStyle } from "components/Heading/Heading.style";
import { Wrapper as ParagraphStyle } from "components/Paragraph/Paragraph.style";
import styled, { css } from "styled-components";
import { rwd } from "styles/Mixins";

const variants = {
  white: css`
    background: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.secondaryBlue};
    ${HeadingStyle},
    ${ParagraphStyle} {
      color: ${({ theme }) => theme.colors.secondaryBlue};
    }
  `,
  dark: css`
    background: ${({ theme }) => theme.colors.secondaryBlue};
    color: ${({ theme }) => theme.colors.white};
    ${HeadingStyle},
    ${ParagraphStyle} {
      color: ${({ theme }) => theme.colors.white};
    }
  `,

  light: css`
    background: ${({ theme }) => theme.colors.primaryBlue};
    color: ${({ theme }) => theme.colors.white};
    ${HeadingStyle},
    ${ParagraphStyle} {
      color: ${({ theme }) => theme.colors.white};
    }
  `,
};

export const Wrapper = styled.section<{ color: "white" | "dark" | "light" }>`
  padding: 96px 0;
  ${(props) => variants[props.color]}

  ${rwd({
    large: `
      padding: 144px 0;
      ${HeadingStyle} {
        padding-bottom: 48px;
      }
    `,
  })}
`;

export const Header = styled.header`
  max-width: 100%;

  h3 {
    padding-bottom: 24px;
  }

  ${rwd({
    medium: `
      max-width: 60%;
   
    `,
  })}
`;

export const Body = styled.div``;

export const Container = styled.div<{
  isHorizontal?: string[];
  isFullWidth?: boolean;
}>`
  width: 100%;
  padding: 0 34px;
  margin: 0 auto;

  ${({ isHorizontal }) => `
    display: flex;
    flex-direction: column;
    row-gap: 48px;
    ${rwd({
      medium: `
        ${
          Array.isArray(isHorizontal) &&
          `
          flex-direction: row;
          column-gap: 48px;

          ${Header} {
            max-width: ${isHorizontal[0]};
          }

          ${Body} {
            display: flex;
            flex: 1;
            max-width: ${isHorizontal[1]};
          }
      `
        }
      `,
      xLarge: `
        max-width: 1400px;
        padding: 0 20px;
      `,
    })}`}

  ${(props) => props.isFullWidth && `max-width: 100% !important;`}
`;
