import styled from "styled-components";
import { font, position, sizing } from "styles/Mixins";

export const Wrapper = styled.div`
  position: relative;
`;

export const Legend = styled.ul`
  ${sizing("100%")}
  display: flex;
  align-items: center;
  position: absolute;
  flex-direction: column;
`;

export const LegendItem = styled.li`
  color: white;
  ${font("BrownLLWeb", "21px", 600)}
`;
