import React, { useEffect, useRef, useState } from "react";
import Footer from "./components/Footer";
import TopBar from "./components/TopBar";
import { CloseTrailer, Trailer, TrailerWrapper, Wrapper } from "./Main.style";
import scrollLock from "scroll-lock";
import ScrollTrigger from "gsap/ScrollTrigger";

export interface IMainProps {
  children: any;
  isOverflow?: boolean;
}

const Main = ({ children, isOverflow = false }: IMainProps) => {
  const [isBootstrapped, setIsBootstrapped] = useState(false);

  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setIsBootstrapped(true);
    }, 50);

    return () => {
      const sts = ScrollTrigger.getAll();
      sts.forEach((st) => st.kill());
    };
  }, []);

  useEffect(() => {
    scrollLock.enablePageScroll();
    if (isPlaying) {
      scrollLock.disablePageScroll();
      videoRef.current?.requestFullscreen();
    } else {
      scrollLock.enablePageScroll();
      videoRef.current?.pause();
    }
  }, [isPlaying]);

  const props: any = {
    setIsPlaying,
  };

  return (
    <>
      <Wrapper isOverflow={isOverflow}>
        <TopBar setIsPlaying={setIsPlaying} />
        {isBootstrapped && children(props)}
        <Footer />
      </Wrapper>
      <TrailerWrapper key={isPlaying.toString()} isPlaying={isPlaying}>
        <CloseTrailer isPlaying={isPlaying} onClick={() => setIsPlaying(false)}>
          Close
        </CloseTrailer>
        <Trailer
          height="auto"
          autoPlay={isPlaying}
          loop
          playsInline={isPlaying}
          isPlaying={isPlaying}
        >
          <source src="/promo.mp4" type="video/mp4" />
        </Trailer>
      </TrailerWrapper>
    </>
  );
};

export default Main;
