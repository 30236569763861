export const createGrid = (
  columns: number,
  rows: number,
  active: number
): any[][] => {
  const grid = Array.from({ length: rows }).map(() =>
    Array.from({ length: columns })
  );

  const activeColumns = Math.ceil(active / rows);

  for (let i = 0; i < activeColumns; i++) {
    for (let j = 0; j < rows; j++) {
      grid[j][i] = true;
    }
  }

  return grid;
};
