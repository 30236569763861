import Heading from "components/Heading";
import gsap from "gsap";
import React, { useEffect, useRef } from "react";
import SpherePyramid from "../SpherePyramid";
import { Circle, Wrapper, Caption } from "./Hero.style";

export interface IHeroProps {
  children?: any;
}

const Hero = ({}: IHeroProps) => {
  const sphereRef = useRef<HTMLDivElement | null>(null);

  return (
    <Wrapper id="hero">
      <Caption l={1} text={`banking \nat its\n finest`} />
      <Circle ref={sphereRef}>
        <SpherePyramid />
      </Circle>
    </Wrapper>
  );
};

export default Hero;
