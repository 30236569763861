import HeadingComponent from "components/Heading";
import styled from "styled-components";
import { rwd } from "styles/Mixins";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 80vh;
  scroll-snap-align: start;

  ${rwd({
    medium: `
      height: 100vh;
    `,
  })}
`;

export const Heading = styled(HeadingComponent)`
  padding-bottom: 24px;
`;
