import React from "react";
import currency from "currency.js";
import { Border, Label, Value, Wrapper } from "./MobileIcon.style";

const SMECustomerProfileMobile = ({ income, employees, icon }: any) => {
  return (
    <Wrapper
      width="300"
      height="300"
      viewBox="0 0 310 310"
    >
      <defs>
        <clipPath id="cut-off-bottom">
          <rect x="0" y="0" width="310" height="220" />
        </clipPath>
      </defs>

      <Border
        cx="155"
        cy="155"
        r="150"
        fill="transparent"
        strokeWidth="2px"
        clipPath="url(#cut-off-bottom)"
      />

      {icon}

      <Label
        x="155"
        y="133"
        textAnchor="middle"
      >
        Umsatz im EUR
      </Label>
      <Value
        x="155"
        y="160"
        textAnchor="middle"
      >
        {currency(income, {
          separator: ".",
          symbol: "",
          precision: 0,
        }).format()}
      </Value>
      <Label
        x="155"
        y="195"
        textAnchor="middle"
      >
        Angestellte
      </Label>

      <Value
        x="155"
        y="230"
        textAnchor="middle"
      >
        {employees}
      </Value>
    </Wrapper>
  );
};

export default SMECustomerProfileMobile;
