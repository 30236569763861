import Paragraph from "components/Paragraph";
import React from "react";
import { Link } from "react-router-dom";
import {
  Wrapper,
  Heading,
  IconWrapper,
  DiscoverLink,
  Discover,
} from "./Episode.style";
import { ReactComponent as Arrow } from "assets/icons/arrow_darkblue.svg";
import { useTranslation } from "react-i18next";

export interface IEpisodeProps {
  title: string;
  excerpt: string;
  url: string;
  icon: JSX.Element;
}

const Episode = ({ title, excerpt, icon, url }: IEpisodeProps) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <IconWrapper>{icon}</IconWrapper>
      <Heading l={3} text={title} />
      <Paragraph text={excerpt} />
      <DiscoverLink to={url}>
        <Discover>
          {t("discover-more")} <Arrow />
        </Discover>
      </DiscoverLink>
    </Wrapper>
  );
};

export default Episode;
