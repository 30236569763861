import { css } from "styled-components";

type PositionSettings = {
  top?: string | number;
  right?: string | number;
  bottom?: string | number;
  left?: string | number;
};

type Position = "absolute" | "sticky" | "fixed";

enum Breakpoint {
  small = 480,
  medium = 768,
  large = 1024,
  xLarge = 1440,
  xXLarge = 1680,
}

export const sizing = (width: string, height?: string, prefix = "") => `
  ${prefix}width: ${width};
  ${prefix}height: ${height || width};
`;

export const circle = (diameter: string) => `
  border-radius: 50%;
  ${sizing(diameter)};
`;

export const position = (position: Position, settings: PositionSettings) => `
  position: ${position};
  ${Object.entries(settings)
    .map(([rule, value]) => `${rule}: ${value};`)
    .join("\n")}
`;

export const font = (family: string, size: string | number, weight = 400) => `
  font-family: ${family};
  font-size: ${size};
  font-weight: ${weight};
`;

export const rwd = (breakpoints: any) => {
  const entries = Object.entries(breakpoints);

  return entries
    .map(
      ([breakpoint, style]) => `
        @media only screen and (min-width: ${Number(
          Breakpoint[breakpoint as any]
        )}px) {
          ${style as string}
        }
      `
    )
    .flat()
    .join("");
};
