import { rwd } from "styles/Mixins";
import styled from "styled-components";

export const Wrapper = styled.div<{ isFullWidth?: boolean }>`
  width: 100%;
  padding: 0 34px;
  margin: 0 auto;

  ${(props) => props.isFullWidth && `max-width: 100% !important;`}

  ${rwd({
    xLarge: `
        max-width: 1400px;
        padding: 0 20px;
      `,
  })}
`;
