import React, { useEffect, useRef, useState, FC } from "react";
import useViewPort from "hooks/useViewport";
import useBlockAnimation from "./useBlockAnimation";
import Heading from "components/Heading";
import Paragraph from "components/Paragraph";
import { useTranslation } from "react-i18next";

import {
  Label,
  Value,
  BlockElement,
  Wrapper,
  Legend,
  LegendItem,
  Description,
} from "./Block.style";

const Block: FC = () => {
  const [currentWalletItem, setCurrentWalletItem] = useState(1);
  const blockRef = useRef<HTMLDivElement>(null);
  const bottomBlockStateOne =
    "M312.192 700.364L0 520.119v216.64l312.193 180.245 312.193-180.245v-216.64z";

  const { width } = useViewPort();

  const blockController = useBlockAnimation();
  const { t } = useTranslation("customer");

  return (
    <Wrapper>
      <BlockElement ref={blockRef}>
        <svg
          style={{ overflow: "visible" }}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 624.39 918.005"
        >
          <g data-name="Group 1145">
            <g data-name="Group 1140">
              <path
                id="bottom-block-side"
                data-name="Path 938"
                d={bottomBlockStateOne}
                fill="#0d3651"
              />
              <path fill="#002b47" d="M312.193 918.004v-216.64" />
              <g data-name="Group 1139">
                <path
                  id="bottom-block-top"
                  data-name="Path 939"
                  d="M0 521.12l312.193 180.245L624.386 521.12 312.192 340.875z"
                  fill="#002b47"
                />
              </g>
            </g>
            <g data-name="Group 1142">
              <path
                id="middle-block-side"
                data-name="Path 940"
                d="M312.192 544.427L0 364.183v71.938l312.193 180.245 312.193-180.245v-71.938z"
                fill="#65c5ef"
              />
              <g data-name="Group 1141">
                <path
                  id="middle-block-top"
                  data-name="Path 941"
                  d="M0 365.182l312.192 180.245 312.193-180.245-312.193-180.244z"
                  fill="#33b2e9"
                />
              </g>
            </g>
            <g>
              <path
                id="top-most-block-side"
                d="M312.192 359.49L0 179.245v71.938l312.192 180.248 312.193-180.245v-71.938z"
                fill="#0d3651"
              />
              <g>
                <path
                  id="top-most-block-top"
                  d="M0 181.245l312.192 179.241 312.193-180.245L312.192 0z"
                  fill="#002b47"
                />
              </g>
            </g>
          </g>
          <g style={{ opacity: 0 }} id="total-wallet-values">
            <Value fill="#fff" x={width < 768 ? "310" : "500"} y="230">
              16,6 Mrd €
            </Value>
            <Label fill="#fff" x={width < 768 ? "310" : "500"} y="275">
              Großunternehmen
            </Label>
            <Value
              fill="#fff"
              x={width < 768 ? "310" : "500"}
              y={width < 768 ? "490" : "410"}
            >
              16,6 Mrd €
            </Value>
            <Label
              fill="#fff"
              x={width < 768 ? "310" : "500"}
              y={width < 768 ? "530" : "455"}
            >
              KMU-Segment
            </Label>
            <Value
              fill="#fff"
              x={width < 768 ? "310" : "500"}
              y={width < 768 ? "740" : "700"}
            >
              50,0 Mrd €
            </Value>
            <Label
              fill="#fff"
              x={width < 768 ? "310" : "500"}
              y={width < 768 ? "780" : "745"}
            >
              Privatkunden
            </Label>
          </g>
          <g style={{ opacity: 0 }} id="per-customer-values">
            <Value fill="#fff" x={width < 768 ? "310" : "500"} y="230">
              1.200.000 €
            </Value>
            <Label fill="#fff" x={width < 768 ? "310" : "500"} y="270">
              Großunternehmen
            </Label>
            <Value fill="#fff" x={width < 768 ? "310" : "500"} y="580">
              5.103 €
            </Value>
            <Label fill="#fff" x={width < 768 ? "310" : "500"} y="620">
              KMU-Segment
            </Label>
            <Value fill="#fff" x={width < 768 ? "310" : "500"} y="700">
              1.266 €
            </Value>
            <Label fill="#fff" x={width < 768 ? "310" : "500"} y="740">
              Privatkunden
            </Label>
          </g>
        </svg>
      </BlockElement>
      <Legend>
        <LegendItem
          isActive={currentWalletItem === 1}
          onClick={() => {
            blockController.tweenFromTo(2, 1);
            setCurrentWalletItem(1);
          }}
        >
          Gesamtwallet
        </LegendItem>

        <LegendItem
          isActive={currentWalletItem === 2}
          onClick={() => {
            blockController.tweenFromTo(1, 2);
            setCurrentWalletItem(2);
          }}
        >
          Wallet pro Kunde
        </LegendItem>
      </Legend>

      <Description>
        <Heading l={3} text={t("stuck-in-the-middle-content-heading")} />
        <Paragraph text={t("stuck-in-the-middle-content")} />
      </Description>
    </Wrapper>
  );
};

export default Block;
