import styled from "styled-components";
import { font, position, rwd } from "styles/Mixins";

export const Wrapper = styled.div`
  ${position("absolute", { top: "200vh", left: 0 })}
  z-index: 12;
  padding: 0 ${(160 / 1920) * 100}vw;
  width: 100%;
  display: flex;
  align-items: flex-start;
`;

export const Legend = styled.ul`
  list-style: none;
  width: 25%;
  z-index: 11;
  display: none;

  ${rwd({
    medium: `
      display: block;
    `,
  })}
`;

export const LegendItem = styled.li`
  ${font("BrownLLWeb", "21px", 500)}
  line-height: 1.67;

  text-align: left;
  color: #fff;
  padding: 24px 0;
  cursor: pointer;
  transition: 0.2s linear color;

  &:hover {
    color: #1da1f2;
  }
`;

export const Content = styled.div`
  padding: 24px 0;
  width: 100%;
  z-index: 10;

  ${rwd({
    medium: `
      width: 50%;
      margin-left: 25%;
    `,
  })}
`;
