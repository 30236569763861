import styled, { css } from "styled-components";
import { flexCentered } from "styles/Layout";
import { font, position, rwd, sizing } from "styles/Mixins";

export const Wrapper = styled.div<{ isOverflow?: boolean }>`
  display: flex;
  flex-direction: column;
  min-height: 200vh;
  background: ${(props) => props.theme.colors.primaryBlue};

  ${(props) =>
    !props.isOverflow &&
    `
    overflow: hidden;
  `}

  position: relative;
`;

export const TrailerWrapper = styled.div<{ isPlaying: boolean }>`
  ${flexCentered}
  ${(props) =>
    props.isPlaying &&
    ` 
    background: #000;
    display: block; 
    ${sizing("100vw", "100vh")}
    z-index: 1001;
    ${position("fixed", { top: 0, left: 0 })}
  `}
`;

export const Trailer = styled.video<{ isPlaying: boolean }>`
  display: none;
  ${position("fixed", { top: 0, left: 0 })}
  ${sizing("100vw", "100vh")}



  ${(props) => props.isPlaying && ` display: block;`}
`;

export const CloseTrailer = styled.span<{ isPlaying: boolean }>`
  color: #fff;
  z-index: 1002;
  display: none;
  ${font("BrownLLWeb", 25, 400)}
  cursor: pointer;
  ${position("absolute", { top: "30px", right: "30px" })}
  ${(props) => props.isPlaying && `display:block;`}
`;
