import { rwd } from "styles/Mixins";
import styled from "styled-components";

export const Wrapper = styled.div<{
  level: number;
  color: string;
}>`
  font-family: BrownLLWeb;
  color: ${(props) => props.color || "#fff"};
  margin: 0;
  white-space: pre-line;

  ${(props) =>
    props.level === 1 &&
    `
    font-size: 60px;
    line-height: 60px;

    ${rwd({
      xLarge: `
        font-size: 210px;
        line-height: 210px;
      `,
    })}
  `}

  ${(props) =>
    props.level === 2 &&
    `
    font-size: 50px;
    line-height: 55px;

    ${rwd({
      xLarge: `
        font-size: 180px;
        line-height: 170px;
      `,
    })}
  `}

  ${(props) =>
    props.level === 3 &&
    `
    font-size: 25px;
    line-height: 31.5px;

    ${rwd({
      xLarge: `
        font-size: 38px;
        line-height: 48px;
      `,
    })}
  `}
`;
