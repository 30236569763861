import styled from "styled-components";
import { default as HeadingComponent } from "components/Heading";
import { font, position, rwd } from "styles/Mixins";
import { Link } from "react-router-dom";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: flex-end;

  ${rwd({
    medium: `
      height: auto;
    `,
  })}
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  padding-bottom: 80px;
  ${rwd({
    medium: `
      padding-bottom: 0;
    `,
    large: `
    
      height: 218px;`,
  })}

  svg {
    height: auto;
    min-height: 187px;

    ${rwd({
      small: `
        min-width: 140px;
        max-width: 280px;
      `,
      xLarge: `
        ${position("absolute", { left: 0 })}
      `,
    })}
  }
`;

export const Heading = styled(HeadingComponent)`
  color: ${(props) => props.theme.colors.secondaryBlue} !important;
  font-size: 21px;
  margin-bottom: 24px;
  padding-bottom: 0 !important;
`;

export const DiscoverLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.secondaryBlue};

  padding-top: 24px;
  display: block;

  ${rwd({
    medium: `
      margin-top: auto;
    `,
  })}
`;

export const Discover = styled.span`
  flex: 0.5;
  max-height: 40px;
  font-size: 21px;

  color: ${({ theme }) => theme.colors.secondaryBlue};
  ${font("BrownLLWeb", "21px", 400)}

  display: flex;
  align-items: center;

  svg {
    margin-left: 12px;
  }
`;
