import Heading from "components/Heading";
import styled from "styled-components";
import { position, rwd } from "styles/Mixins";

export const Wrapper = styled.section`
  background: ${({ theme }) => theme.colors.primaryBlue};
  width: 100%;
  position: relative;
  padding: 0 34px;
  padding-top: ${((970 + 372 + 80) / 1920) * 100}vw;
  padding-bottom: 54px;
  overflow: hidden;
  height: 770vh;

  ${rwd({
    small: `
      padding: 0 ${(160 / 1920) * 100}vw;
      padding-top: ${((970 + 372 + 120) / 1920) * 100}vw;
    `,
    medium: `
      height: 930vh;
      padding-top: ${((970 + 1272) / 1920) * 100}vw;
    `,
    large: `
      padding-top: ${((970 + 672) / 1920) * 100}vw;
    `,
  })}
`;

export const Caption = styled(Heading)`
  ${position("absolute", { top: "140px" })}
  z-index: 2;
  font-size: 60px;

  ${rwd({
    medium: `
      top: ${(276 / 1920) * 100}vw;
      font-size: ${(210 / 1920) * 100}vw;
      line-height: ${(210 / 1920) * 100}vw;
    `,
  })}
`;

export const Circle = styled.div`
  ${position("absolute", { top: `${(142 / 1920) * 100}vw`, left: 0 })}
  z-index: 1;
  width: 100%;
  height: 100%;
`;
