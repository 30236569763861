import Section from "components/Section";
 
import commercialCustomersIcon from "assets/icons/commercial-customers.svg";
import corporateCustomersIcon from "assets/icons/corporate-customers.svg";
import mediumSizedCompaniesIcon from "assets/icons/medium-sized-companies.svg";

import { ReactComponent as PersonaOne } from "assets/icons/persona1.svg";
import { ReactComponent as PersonaTwo } from "assets/icons/persona2.svg";
import { ReactComponent as PersonaThree } from "assets/icons/persona3.svg";

import React from "react";
import { useTranslation } from "react-i18next";
import Profile from "./components/Profile";
import { Wrapper } from "./CustomersProfiles.style";

const CustomersProfiles = () => {
  const { t } = useTranslation("customer");
  return (
    <Section color="dark" title={t("heading-3")} excerpt={t("content-3")}>
      <Wrapper>
      <Profile
        label={t("content-3-item-1-heading")}
        featuresLabel="Handwerksbetrieb"
        features={[
          [t("content-3-list-heading-1"), t("content-3-item-1-list-1")],
          [t("content-3-list-heading-2"), t("content-3-item-1-list-2")],
          [t("content-3-list-heading-3"), t("content-3-item-1-list-3")],
          [t("content-3-list-heading-4"), t("content-3-item-1-list-4")],
          [t("content-3-list-heading-5"), t("content-3-item-1-list-5")],
        ]}
        icon={commercialCustomersIcon}
        micon={<PersonaOne x="125" y="36" width="60" height="65" />}
        sale={Number(t("content-3-item-1-turnover"))}
        employees={Number(t("content-3-item-1-employees"))}
      />
      <Profile
        label={t("content-3-item-2-heading")}
        featuresLabel="Händler"
        features={[
          [t("content-3-list-heading-1"), t("content-3-item-2-list-1")],
          [t("content-3-list-heading-2"), t("content-3-item-2-list-2")],
          [t("content-3-list-heading-3"), t("content-3-item-2-list-3")],
          [t("content-3-list-heading-4"), t("content-3-item-2-list-4")],
          [t("content-3-list-heading-5"), t("content-3-item-2-list-5")],
        ]}
        icon={mediumSizedCompaniesIcon}
        micon={<PersonaTwo x="125" y="36" width="60" height="65" />}
        sale={Number(t("content-3-item-2-turnover"))}
        employees={Number(t("content-3-item-2-employees"))}
      />
      <Profile
        label={t("content-3-item-3-heading")}
        featuresLabel="Anlagenbauer"
        features={[
          [t("content-3-list-heading-1"), t("content-3-item-3-list-1")],
          [t("content-3-list-heading-2"), t("content-3-item-3-list-2")],
          [t("content-3-list-heading-3"), t("content-3-item-3-list-3")],
          [t("content-3-list-heading-4"), t("content-3-item-3-list-4")],
          [t("content-3-list-heading-5"), t("content-3-item-3-list-5")],
        ]}
        icon={corporateCustomersIcon}
        micon={<PersonaThree x="125" y="36" width="60" height="65" />}
        sale={Number(t("content-3-item-3-turnover"))}
        employees={Number(t("content-3-item-3-employees"))}
      />
      </Wrapper>
    </Section>
  );
};

export default CustomersProfiles;
