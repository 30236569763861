import Paragraph from "components/Paragraph";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import React, { useEffect } from "react";
import { Heading, Wrapper } from "./OverviewStep.style";

export interface IOverviewStepProps {
  id: string;
  heading: string;
  content: string;
}

const OverviewStep = ({ id, heading, content }: IOverviewStepProps) => {
  useEffect(() => {
    const st = ScrollTrigger.create({
      trigger: `#${id}`,

      start: "top top",
      end: "+=100%",
      onEnter: () => {
        const label = document.querySelector(`#${id}-label`);
        gsap.to(label, { color: "#1da1f2" });

        document
          .querySelectorAll(`#${id}-floor *, #${id}-floor`)
          .forEach((el, i) => {
            gsap.to(el, {
              fill: () =>
                i === 0 ? "#0084BF" : i === 1 ? "#0084BF" : "#019EE3",
              strokeWidth: 0,
              immediateRender: false,
            });
          });
      },
      onEnterBack: () => {
        const label = document.querySelector(`#${id}-label`);
        gsap.to(label, { color: "#1da1f2" });

        document
          .querySelectorAll(`#${id}-floor *, #${id}-floor`)
          .forEach((el, i) => {
            gsap.to(el, {
              fill: () =>
                i === 0 ? "#0084BF" : i === 1 ? "#0084BF" : "#019EE3",
              strokeWidth: 0,
              immediateRender: false,
            });
          });
      },
      onLeave: () => {
        const label = document.querySelector(`#${id}-label`);
        gsap.to(label, { color: "#fff" });

        document
          .querySelectorAll(`#${id}-floor *, #${id}-floor`)
          .forEach((el) => {
            gsap.to(el, {
              fill: "#002b47",
              strokeWidth: 1,
              immediateRender: false,
            });
          });
      },
      onLeaveBack: () => {
        const label = document.querySelector(`#${id}-label`);
        gsap.to(label, { color: "#fff" });
        document
          .querySelectorAll(`#${id}-floor *, #${id}-floor`)
          .forEach((el) => {
            gsap.to(el, {
              fill: "#002b47",
              strokeWidth: 1,
              immediateRender: false,
            });
          });
      },
    });

    return () => {
      st.kill();
    };
  }, []);

  return (
    <Wrapper id={id}>
      <Heading l={3} text={heading} />
      <Paragraph text={content} />
    </Wrapper>
  );
};

export default OverviewStep;
