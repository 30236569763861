import React from "react";
import Block from "./components/Block";
import { Wrapper, Heading } from "./StuckInTheMiddle.style";

const StuckInTheMiddle = () => {
  return (
    <Wrapper>
      <Heading l={2} text={`Stuck \n in the \n middle`} />
      <Block />
    </Wrapper>
  );
};

export default StuckInTheMiddle;
