import { useEffect, useLayoutEffect, useState } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

const topBlockSidePerCustomerPath =
  "M312.192 359.49L0 179.245v510l312.192 180.248 312.193-180.245v-510z";

const middleBlockSidePerCustomerPath =
  "M312.192 544.427L0 364.183v0l312.193 180.245 312.193-180.245v0z";

const bottomBlockSidePerCustomerPath =
  "M312.192 700.364L0 520.119v0l312.193 180.245 312.193-180.245v0z";

export default (): any => {
  const [controller, setController] = useState<any>();

  useLayoutEffect(() => {
    gsap.set(
      [
        "#top-most-block-top",
        "#top-most-block-side",
        "#middle-block-top",
        "#middle-block-side",
        "#bottom-block-top",
        "#bottom-block-side",
      ],
      {
        fill: "#002b47",
      }
    );

    gsap.set(["#bottom-block-top", "#bottom-block-side"], {
      y: -236,
    });

    gsap.set(["#middle-block-top", "#middle-block-side"], {
      y: -128,
    });

    gsap.set(["#total-wallet-values", "#per-customer-values"], {
      opacity: 0,
    });
  }, []);

  useEffect(() => {
    const masterTl = gsap.timeline({ paused: true });

    masterTl
      .to(
        ["#top-most-block-side", "#bottom-block-side"],
        {
          fill: "#0d3651",
        },
        0
      )
      .to(
        "#middle-block-side",
        {
          fill: "#65c5ef",
        },
        0
      )
      .to(
        "#middle-block-top",
        {
          fill: "#33b2e9",
        },
        0
      )
      .to(
        ["#middle-block-top", "#middle-block-side"],
        {
          immediateRender: false,
          y: 0,
        },
        0
      )
      .to(
        ["#bottom-block-top", "#bottom-block-side"],
        {
          immediateRender: false,
          y: 0,
        },
        0
      )
      .to(
        "#total-wallet-values",
        {
          opacity: 1,
        },
        0
      )
      .to(
        "#total-wallet-values",
        {
          opacity: 0,
        },
        1
      )
      .to(
        "#per-customer-values",
        {
          opacity: 1,
        },
        1
      )
      .to(
        "#top-most-block-side",
        {
          attr: {
            d: topBlockSidePerCustomerPath,
          },
        },
        1
      )
      .to(
        "#middle-block-side",
        {
          y: 216.64 + 71.938 + 50,
          attr: {
            d: middleBlockSidePerCustomerPath,
          },
        },
        1
      )
      .to(
        "#middle-block-top",
        {
          y: 216.64 + 71.938 + 60,
        },
        1
      )
      .to(
        "#bottom-block-top",
        {
          y: 216.64,
        },
        1
      )
      .to(
        "#bottom-block-side",
        {
          y: 216.64,
          attr: {
            d: bottomBlockSidePerCustomerPath,
          },
        },
        1
      );

    const st = ScrollTrigger.create({
      trigger: "#bottom-block-top",
      start: `top+=${window.innerWidth < 670 ? "0" : "200"} center`,
      end: "+=100%",
      invalidateOnRefresh: true,
      onEnter: () => masterTl.tweenTo(1),
      onEnterBack: () => masterTl.tweenTo(1),
      onLeave: () => masterTl.tweenTo(0),
      onLeaveBack: () => masterTl.tweenTo(0),
    });

    setController(masterTl);

    return () => {
      st.kill();
      masterTl.kill();
    };
  }, []);

  return controller;
};
