import React, { FC } from "react";
import { Link, useLocation } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { ReactComponent as LinkedIn } from "assets/icons/linkedin.svg";
import { Copyright, Copyrights, LinkItem, Links, Main, NextChapterWrapper, Social, Wrapper } from "./Footer.style";
import NextChapter from "../NextChapter";

export interface IFooterProps {}

const Footer: FC<IFooterProps> = ({}) => {
  const { t } = useTranslation();
  const location = useLocation();

  const pathMap: Record<string, any> = {
    "/": {
      label: t("menu-1"),
      path: "/segment",
    },
    "/segment": {
      label: t("menu-2"),
      path: "/wettbewerb",
    },
    "/wettbewerb": {
      label: t("menu-3"),
      path: "/strategie",
    },
    "/strategie": {
      label: t("menu-4"),
      path: "/umsetzung",
    },
    "/umsetzung": {
      label: "Get in touch",
      path: "",
    },
    "/segment/": {
      label: t("menu-2"),
      path: "/wettbewerb",
    },
    "/wettbewerb/": {
      label: t("menu-3"),
      path: "/strategie",
    },
    "/strategie/": {
      label: t("menu-4"),
      path: "/umsetzung",
    },
    "/umsetzung/": {
      label: "Get in touch",
      path: "",
    },
  };

  return (
    <Wrapper>
      {pathMap[location.pathname] && (
        <NextChapterWrapper>
          <NextChapter
            link={pathMap[location.pathname].path}
            label={pathMap[location.pathname].label}
          />
        </NextChapterWrapper>
      )}
      <Main>
        <Social>
          <a href="https://www.linkedin.com/company/zebapplied" target="_blank">
            <LinkedIn width="30" />
          </a>
        </Social>
        <Copyrights>
          <Copyright>
            &copy; zeb.applied GmbH 2021. All rights reserved.
          </Copyright>
          <Links>
            <LinkItem>
              <Link to="/privacy">{t("footer-privacy")}</Link>
            </LinkItem>
            <LinkItem>
              <Link to="/imprint">{t("footer-imprint")}</Link>
            </LinkItem>
          </Links>
        </Copyrights>
      </Main>
    </Wrapper>
  );
};

export default Footer;
