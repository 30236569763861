export default [
  {
    label: "Sparkassen",
    value: 70,
    hoverValue: 56,
  },
  {
    label: "VR-Banken",
    value: 68,
    hoverValue: 54,
  },
  {
    label: "Deutsche Bank",
    value: 124,
    hoverValue: 100,
  },
  {
    label: "Commerzbank",
    value: 89,
    hoverValue: 72,
  },
  {
    label: "Unicredit",
    value: 86,
    hoverValue: 69,
  },
];
