import React from "react";
import {
  Caption,
  Circle,
  InnerCircle,
  PlayHero,
  StyledHeading,
  TextContent,
  Wrapper,
} from "./Hero.style";
import { ReactComponent as Play } from "../../assets/play.svg";
import Paragraph from "components/Paragraph";

export interface IHeroProps {}

const Hero = ({}: IHeroProps) => (
  <Wrapper>
    <Caption l={1} text={`Let's get \n it on`} />
    <PlayHero />
    <TextContent>
      <StyledHeading l={3} text="Eine umfassende Architektur" />
      <Paragraph text="Das moderne, digitale KMU-Banking ist die Summe zusammenhängender Bausteine. Nur mit der wohldefinierten Gesamtarchitektur aus überzeugendem Antrieb (Mission Model), optimaler Geschäftsausrichtung (Business Model) und effizientem Operating Model (Organisation, Technologie/IT, Steuerung) wird durch das passende Mindset und den richtigen Aufsatz der Umsetzung das Geschäft mit KMU zu einem nachhaltigen Businesscase." />
    </TextContent>
  </Wrapper>
);

export default Hero;
