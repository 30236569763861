import styled from "styled-components";
import { rwd } from "styles/Mixins";

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-auto-rows: 1fr;
  row-gap: 80px;

  ${rwd({
    large: `
      column-gap: 80px;
      grid-template-columns: repeat(4, minmax(0, 1fr));
    `,
  })}
`;
