import styled from "styled-components";
import { font, rwd } from "styles/Mixins";

export const Wrapper = styled.footer`
  background-color: ${(props) => props.theme.colors.secondaryBlue};
  padding: 80px ${(92 / 1920) * 100}%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 200px;
  position: relative;
  z-index: 11;
  margin-top: auto;

  ${rwd({
    medium: `
      display: block;
      height: auto;
      padding: 80px 264 / 1920 * 100%;
    `,
  })}
`;

export const NextChapterWrapper = styled.div`
  display: flex;
  justify-content: center;

  ${rwd({
    medium: `
      justify-content: flex-end;
      padding-bottom: 240px;
    `,
  })}
`;

export const Main = styled.div`
  margin-top: auto;
  ${rwd({
    medium: `
        margin-top: auto;
    `,
  })}
`;

export const Social = styled.div`
  display: flex;
  justify-content: center;

  padding-bottom: 30px;
  column-gap: 50px;
  align-items: center;

  ${rwd({
    medium: `
       justify-content: flex-end;
    `,
  })}
`;

export const Copyrights = styled.div`
  background: ${(props) => props.theme.colors.secondaryBlue};
  width: 100%;
  color: ${(props) => props.theme.colors.white};
  display: flex;
  justify-content: center;
  margin-top: auto;
  flex-direction: column;

  ${rwd({
    small: `
     flex-direction: row;
    `,
  })}
`;

export const Copyright = styled.span`
  display: block;
  text-align: center;
  grid-area: copyright;
  color: ${(props) => props.theme.colors.white};
  ${font("BrownLLWeb", "16px", 400)}
  order: 2;
  margin-top: 40px;
  justify-content: center;
  padding: 0 16%;
  line-height: 24px;

  ${rwd({
    small: `
      ${font("BrownLLWeb", "20px", 400)}
      text-align: left;
      margin-top: 0;
      padding: 0;
    `,
    medium: `
      justify-content: flex-start;
      order: 1;
      width: 100%;
    `,
  })}
`;

export const Links = styled.ul`
  order: 1;
  grid-area: links;
  display: flex;
  align-items: center;
  flex-direction: column;
  list-style: none;
  padding: 0;
  align-self: end;
  width: 100%;
  margin: 0;

  ${rwd({
    medium: `
      flex-direction: row;
      justify-content: flex-end;
      order: 2;
      margin: 0 -24px;
    `,
  })}
`;

export const LinkItem = styled.li`
  ${font("BrownLLWeb", "21px", 400)}
  color: ${(props) => props.theme.colors.white};
  line-height: 35px;
  padding: 0px 24px;

  a {
    color: inherit;
    text-decoration: none;
  }

  ${rwd({
    small: `
      padding: 0 24px;
    `,
  })}
`;
