import ButtonComponent from "components/Button";
import Section from "components/Section";
import TextFieldComponent from "components/TextField";
import styled from "styled-components";
import { font, position, rwd } from "styles/Mixins";
import HeadingComponent from "components/Heading";

import slope from "../../assets/slope.svg";

export const Wrapper = styled(Section)`
  background-image: url(${slope});
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: left bottom;

  padding-bottom: ${(1292 / 1920) * 100}vw;
  position: relative;

  ${rwd({
    medium: `
    padding-bottom: ${(752 / 1920) * 100}vw;
    `,
  })}
`;

export const Heading = styled(HeadingComponent)`
  padding-bottom: 24px;
`;

export const Form = styled.form`
  display: flex;
  align-items: center;
  width: 100%;

  border: 1px solid #e3e3e3;
  padding: 10px;
  margin-top: 32px;
  flex-direction: column;

  ${rwd({
    medium: `
      height: 70px;
      flex-direction: row;
    `,
  })}
`;

export const TextField = styled(TextFieldComponent)`
  flex-basis: 100%;
  min-height: 50px;
  width: 100%;

  ${rwd({
    medium: `
      flex-basis: 75%;
      height: 50px;
    `,
  })}
`;

export const Button = styled(ButtonComponent)`
  height: 50px;
  ${font("BrownLLWeb", "20px", 600)}
  width: 100%;

  ${rwd({
    medium: `
      width: initial;
      padding-left: 32px;
      padding-right: 32px;
    `,
  })}
`;

export const PrivacyText = styled.p`
  ${font("BrownLLWeb", "16px", 400)}
  line-height: 1.33;

  a {
    color: ${(props) => props.theme.colors.secondaryBlue};
  }
`;

export const Plane = styled.img`
  ${position("absolute", {
    right: (240 / 1920) * 100 + "vw",
    bottom: (420 / 1920) * 100 + "vw",
  })}
  max-width: 100%;
  height: auto;
  display: none;

  ${rwd({
    medium: `
      display: initial;
    `,
  })}
`;
