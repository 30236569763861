import { useEffect, useState } from "react";

type Viewport = {
  width: number;
  height: number;
};

const useViewPort = (): Viewport => {
  const [viewport, setViewport] = useState<{ width: number; height: number }>(
    () => ({
      width: window.innerWidth,
      height: window.window.innerHeight,
    })
  );

  const setViewportHandle = () =>
    setViewport({
      width: window.innerWidth,
      height: window.innerHeight,
    });

  useEffect(() => {
    window.addEventListener("resize", setViewportHandle);

    return () => {
      window.removeEventListener("resize", setViewportHandle);
    };
  }, []);

  return viewport;
};

export default useViewPort;
