import Heading from "components/Heading";
import Paragraph from "components/Paragraph";
import React from "react";
import { Content, Figure, Image, Wrapper } from "./OverviewRow.style";

export interface IOverviewRowProps {
  img: string;
  heading: string;
  content: string;
}

const OverviewRow = ({ img, heading, content }: IOverviewRowProps) => (
  <Wrapper>
    <Figure>
      <Image src={img} />
    </Figure>

    <Content>
      <Heading l={3} text={heading} />
      <Paragraph text={content} />
    </Content>
  </Wrapper>
);

export default OverviewRow;
