import styled from "styled-components";
import { flexCentered } from "styles/Layout";
import { font } from "styles/Mixins";

export const Wrapper = styled.button<{ type?: string }>`
  width: 216px;
  height: 34px;
  border: solid 1px ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.primaryBlue};
  color: ${(props) => props.theme.colors.white};
  outline: none;
  text-decoration: none;
  z-index: 1;
  ${flexCentered}
  ${font("BrownLLWeb", "20px", 600)}
`;
