import Heading from "components/Heading";
import Paragraph from "components/Paragraph";
import Main from "layouts/Main";
import React from "react";
import { Wrapper } from "./Privacy.style";

export interface IPrivacyProps {
  children: any;
}

const Privacy = ({}: IPrivacyProps) => (
  <Main>
    {() => (
      <Wrapper>
        <Heading l={1} text="Datenschutz" />
        <Heading l={3} text="Allgemeine Hinweise" />
        <Paragraph
          isHtml
          text="
          Die folgenden Hinweise geben einen einfachen Überblick darüber, was
          mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website
          besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
          persönlich identifiziert werden können. Ausführliche Informationen zum
          Thema Datenschutz entnehmen Sie der folgenden Datenschutzerklärung.
        "
        />
        <Heading l={3} text="Name und Anschrift des Verantwortlichen" />
        <Paragraph
          isHtml
          text="
          Die verantwortliche Stelle für die Datenverarbeitung auf dieser
          Webseite ist:
        "
        />
        <Paragraph
          isHtml
          text="
          zeb.applied GmbH
          <br />
          Hammer Str. 165
          <br />
          48153 Münster
          <br />
          Deutschland
        "
        />
        {/* <Paragraph
        isHtml text="
          E-Mail:
          <a href="mailto:datenschutz@zeb-applied.com">
            datenschutz@zeb-applied.com
          </a>
          <br />
          Website:
          <a href="https://www.zeb-applied.com" target="_blank" rel="noopener">
            www.zeb-applied.com
          </a>
        " /> */}
        <Paragraph
          isHtml
          text="
          Verantwortliche Stelle ist die natürliche oder juristische Person, die
          allein oder gemeinsam mit anderen über die Zwecke und Mittel der
          Verarbeitung von personenbezogenen Daten (z.B. E-Mail-Adressen,
          IP-Adressen o. Ä.) entscheidet.
        "
        />
        <Heading l={3} text="Umfang der Verarbeitung personenbezogener Daten" />
        <Paragraph
          isHtml
          text="
          Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
          mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in ein
          Kontaktformular eingeben.
        "
        />
        <Paragraph
          isHtml
          text="
          Andere Daten werden automatisch oder nach Ihrer Einwilligung beim
          Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor allem
          technische Daten (z. B. Internetbrowser, Betriebssystem oder Uhrzeit
          des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch,
          sobald Sie diese Website betreten.
        "
        />
        <Paragraph
          isHtml
          text="
          Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung
          der Website zu gewährleisten. Andere Daten können zur Analyse Ihres
          Nutzerverhaltens verwendet werden.
        "
        />
        <Heading l={3} text="Welche Rechte haben Sie bezüglich Ihrer Daten?" />
        <Paragraph
          isHtml
          text="
          Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft,
          Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu
          erhalten. Sie haben außerdem ein Recht, die Berichtigung oder Löschung
          dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur
          Datenverarbeitung erteilt haben, können Sie diese Einwilligung
          jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht,
          unter bestimmten Umständen die Einschränkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein
          Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
        "
        />
        <Paragraph
          isHtml
          text="
          Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich
          jederzeit unter der im Impressum angegebenen Adresse an uns wenden.
        "
        />
        <Heading l={3} text="Analyse-Tools und Tools von Drittanbietern" />
        <Paragraph
          isHtml
          text="
          Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch
          ausgewertet werden. Das geschieht vor allem mit Cookies und mit
          sogenannten Analyseprogrammen.
        "
        />
        <Paragraph
          isHtml
          text="
          Detaillierte Informationen zu diesen Analyseprogrammen finden Sie in
          der folgenden Datenschutzerklärung.
        "
        />
        <Heading l={3} text="Hosting und Content Delivery Networks (CDN)" />
        <Paragraph
          isHtml
          text="
          Diese Website wird bei einem externen Dienstleister gehostet (Hoster).
          Die personenbezogenen Daten, die auf dieser Website erfasst werden,
          werden auf den Servern des Hosters gespeichert. Hierbei kann es sich
          v.a. um IP-Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten,
          Vertragsdaten, Kontaktdaten, Namen, Webseitenzugriffe und sonstige
          Daten, die über eine Website generiert werden, handeln.
        "
        />
        <Paragraph
          isHtml
          text="
          Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserfüllung
          gegenüber unseren potenziellen und bestehenden Kunden (Art. 6 Abs. 1
          lit. b DSGVO) und im Interesse einer sicheren, schnellen und
          effizienten Bereitstellung unseres Online-Angebots durch einen
          professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO).
        "
        />
        <Paragraph
          isHtml
          text="
          Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies zur
          Erfüllung seiner Leistungspflichten erforderlich ist und unsere
          Weisungen in Bezug auf diese Daten befolgen.
        "
        />
        <Heading l={3} text="Allgemeine Hinweise und Pflichtinformationen" />
        <Paragraph
          isHtml
          text="
          Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten
          sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und
          entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
          Datenschutzerklärung.
        "
        />
        <Paragraph
          isHtml
          text="
          Wenn Sie diese Website benutzen, werden verschiedene personenbezogene
          Daten erhoben.Personenbezogene Daten sind Daten, mit denen Sie
          persönlich identifiziert werden können. Die vorliegende
          Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir
          sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das
          geschieht.
        "
        />
        <Paragraph
          isHtml
          text="
          Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B.
          bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann.
          Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist
          nicht möglich.
        "
        />
        <Heading l={3} text="Widerspruchs- und Beseitigungsmöglichkeit" />
        <Paragraph
          isHtml
          text="
          Die Erfassung der Daten zur Bereitstellung der Website und die
          Speicherung der Daten in Logfiles ist für den Betrieb der
          Internetseite zwingend erforderlich. Es besteht folglich seitens des
          Nutzers keine Widerspruchsmöglichkeit.
        "
        />
        <Heading
          l={3}
          text="Beschwerderecht bei der zuständigen Aufsichtsbehörde"
        />
        <Paragraph
          isHtml
          text="
          Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
          Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
          Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes
          oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht
          besteht unbeschadet anderweitiger verwaltungsrechtlicher oder
          gerichtlicher Rechtsbehelfe.
        "
        />
        <Heading l={3} text="Recht auf Datenübertragbarkeit" />
        <Paragraph
          isHtml
          text="
          Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
          oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich
          oder an einen Dritten in einem gängigen, maschinenlesbaren Format
          aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an
          einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es
          technisch machbar ist.
        "
        />
        <Heading l={3} text="SSL- bzw. TLS-Verschlüsselung" />
        <Paragraph
          isHtml
          text="
          Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
          Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder
          Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw.
          TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie
          daran, dass die Adresszeile des Browsers von „http://“ auf „https://“
          wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
        "
        />
        <Paragraph
          isHtml
          text="
          Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die
          Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen
          werden.
        "
        />
        <Heading l={3} text="Auskunft, Löschung und Berichtigung" />
        <Paragraph
          isHtml
          text="
          Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit
          das Recht auf unentgeltliche Auskunft, ob personenbezogenen Daten, die
          sie betreffen, von uns verarbeitet werden. Liegt eine solche
          Verarbeitung vor, können Sie von dem Verantwortlichen über folgende
          Informationen Auskunft verlangen:
          <br />- Zweck der Verarbeitung
          <br />- Kategorie der Daten
          <br />- Empfänger bzw. Kategorie der Empfänger der Daten
          <br />- Dauer der Speicherung der Daten oder, falls konkrete Angaben
          hierzu nicht möglich sind, Kriterien für die Festlegung der
          Speicherdauer
          <br />- ggf. ein Recht auf Berichtigung oder Löschung dieser Daten.
        "
        />
        <Paragraph
          isHtml
          text="
          Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten
          können Sie sich jederzeit unter der im Impressum angegebenen Adresse
          an uns wenden.
        "
        />
        <Heading l={3} text="Recht auf Einschränkung der Verarbeitung" />
        <Paragraph
          isHtml
          text="
          Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen. Hierzu können Sie sich jederzeit
          unter der im Impressum angegebenen Adresse an uns wenden. Das Recht
          auf Einschränkung der Verarbeitung besteht in folgenden Fällen:
          <br />- Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
          personenbezogenen Daten bestreiten, benötigen wir in der Regel Zeit,
          um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das Recht,
          die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
          verlangen.
          <br />- Wenn die Verarbeitung Ihrer personenbezogenen Daten
          unrechtmäßig geschah/geschieht, können Sie statt der Löschung die
          Einschränkung der Datenverarbeitung verlangen.
          <br />- Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen,
          Sie sie jedoch zur Ausübung, Verteidigung oder Geltendmachung von
          Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung
          die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
          verlangen.
          <br />- Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt
          haben, muss eine Abwägung zwischen Ihren und unseren Interessen
          vorgenommen werden. Solange noch nicht feststeht, wessen Interessen
          überwiegen, haben Sie das Recht, die Einschränkung der Verarbeitung
          Ihrer personenbezogenen Daten zu verlangen.
        "
        />
        <Paragraph
          isHtml
          text="
          Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt
          haben, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit
          Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung
          von Rechtsansprüchen oder zum Schutz der Rechte einer anderen
          natürlichen oderjuristischen Person oder aus Gründen eines wichtigen
          öffentlichen Interesses der Europäischen Union oder eines
          Mitgliedstaats verarbeitet werden.
        "
        />
        <Heading l={3} text="Widerspruch gegen Werbe-E-Mails" />
        <Paragraph
          isHtml
          text="
          Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
          Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter
          Werbung und Informationsmaterialien wird hiermit widersprochen. Die
          Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im
          Falle der unverlangten Zusendung von Werbeinformationen, etwa durch
          Spam-E-Mails, vor.
        "
        />
        <Heading l={3} text="Datenerfassung auf dieser Website" />
        <Paragraph
          isHtml
          text="
          <strong>Server-Log-Dateien</strong>
          <br />
          Die Hoster der Seiten erheben und speichern automatisch Informationen
          in so genannten Server-Log-Dateien, die Ihr Browser automatisch an uns
          übermittelt. Dies sind:
          <br />- Browsertyp und Browserversion
          <br />- verwendetes Betriebssystem
          <br />- Referrer URL
          <br />- Hostname des zugreifenden Rechners
          <br />- Uhrzeit der Serveranfrage
          <br />- IP-Adresse
        "
        />
        <Paragraph
          isHtml
          text="
          Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht
          vorgenommen.Die Erfassung dieser Daten erfolgt auf Grundlage von Art.
          6 Abs. 1 lit. f DSGVO. Die Hoster haben ein berechtigtes Interesse an
          der technisch fehlerfreien Bereitstellung seines Dienstes - hierzu
          müssen die Server-Log-Files erfasst werden.
        "
        />
        <Heading l={3} text="Newsletter" />{" "}
        <Paragraph
          isHtml
          text="
          KMUBanking versendet regelmäßig E-Mail Newsletter an Kunden und
          Interessierte, die sich in unseren Verteiler eingetragen haben. Zum
          Versand unseres Newsletters verwenden wir den Listenprovider Campaign
          Monitor (404/3-5 Stapleton Ave, Sutherland NSW 2232, Sydney,
          Australia).Wenn Sie sich für unseren Newsletter registrieren, werden
          die Daten (Name, E-Mail-Adresse), die Sie bei der
          Newsletter-Registrierung angeben, an Campaign Monitor übertragen und
          dort gespeichert.
        "
        />
        <Heading l={3} text="Newsletteranmeldung und -versand" />{" "}
        <Paragraph
          isHtml
          text={`
          Sie können auf unserer Internetseite einen Newsletter bestellen. Bitte
          beachten Sie, dass wir für die Anmeldung zum Newsletter bestimmte
          Daten (mindestens Ihre E-Mail-Adresse) benötigen.Der Versand des
          Newsletters erfolgt nur dann, wenn Sie uns eine ausdrückliche
          Einwilligung gem. Art. 6 Abs. 1 S. 1 lit. a DSGVO gegeben haben. Nach
          erfolgter Bestellung auf unseren Webseiten erhalten Sie eine
          Bestätigungs-E-Mail an die von Ihnen angegebene E-Mail-Adresse (sog.
          Double-Opt-In). Sie können Ihre Einwilligung jederzeit widerrufen.
          Eine unkomplizierte Möglichkeit zum Widerruf erhalten Sie z. B. über
          den in jedem Newsletter vorhandenen Link zur Abbestellung.Im Rahmen
          der Newsletteranmeldung speichern wir über die bereits genannten Daten
          hinaus weitere Daten, sofern diese erforderlich sind, damit wir
          nachweisen können, dass Sie unseren Newsletter bestellt haben. Dies
          kann die Speicherung der vollständigen IP-Adresse zum Zeitpunkt der
          Bestellung bzw. der Bestätigung des Newsletter umfassen, sowie eine
          Kopie der von uns versendeten Bestätigungsmail. Die entsprechende
          Datenverarbeitung erfolgt auf Grundlage von Art. 6 Abs. 1 S. 1 lit. f
          DSGVO und erfolgt in dem Interesse, über die Rechtmäßigkeit des
          Newsletterversands Rechenschaft ablegen zu können.Zum Versand unseres
          Newsletters verwenden wir den Listenprovider Campaign Monitor (404/3-5
          Stapleton Ave, Sutherland NSW 2232, Sydney, Australia). Wenn Sie sich
          für unseren Newsletter registrieren, werden die Daten (Name,
          E-Mail-Adresse), die Sie bei der Newsletter-Registrierung angeben, an
          Campaign Monitor übertragen und dort gespeichert. Der Dienstleister
          Campaign Monitor hat seinen Sitz in Australien, die Daten werden auf
          Servern / Rechenzentren in den USA verarbeitet. Campaign Monitor
          bietet umfangreiche Analysemöglichkeiten darüber, wie die Newsletter
          geöffnet und benutzt werden. Diese Analysen sind Gruppenbezogen und
          werden von uns nicht zur individuellen Auswertung der
          Newsletter-Empfänger verwendet. Weitere Informationen zu Campaign Monitor und dem Datenschutz bei Campaign Monitor finden Sie <a href="http://www.campaignmonitor.com/privacy/">hier</a>
        `}
        />
        <Paragraph
          isHtml
          text={`
          Sie können dem Erhalt des Newsletters jederzeit mit Wirkung für die Zukunft widersprechen (z.B. per <a href="mailto:kmubanking@zeb-applied.com">Email</a>).
        `}
        />
      </Wrapper>
    )}
  </Main>
);

export default Privacy;
