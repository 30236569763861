import styled from "styled-components";
import { rwd, sizing } from "styles/Mixins";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 38px;
  cursor: pointer;
  padding: 12px;
  padding-right: 0;

  ${rwd({
    medium: `
      display: none;
      
    `,
  })}
`;

export const Bar = styled.span<{ isActive: boolean }>`
  ${sizing("34px", "2px")}
  background-color: ${(props) => props.theme.colors.white};
  transform-origin: 25% 50%;
  transition: 0.4s ease-in-out transform;

  ${(props) =>
    props.isActive &&
    `
    &:first-of-type {
      transform: rotate(45deg);
    }

    &:last-of-type {
      transform: rotate(-45deg);
    }
  `}
`;
