import React, { FC } from "react";
import {Link as RouterLink} from 'react-router-dom'
import { ReactComponent as Arrow } from "assets/icons/arrow_white.svg";
import { LinkWrapper, Link, Wrapper, ChapterLabel, ChapterSubtitle } from "./NextChapter.style";

export interface INextChapterProps {
  label: string;
  link: string;
}

const NextChapter: FC<INextChapterProps> = ({ label, link }) => {
  return (
    <LinkWrapper>
      {link === "" && (
        <Link href="ma&#105;lto&#58;%6B%6&#68;uba&#110;king%4&#48;ze%62&#45;applie%6&#52;&#46;%&#54;3&#111;m?subject=kmubanking.digital%20%7C%20Pers%C3%B6nliche%20Demo&body=Liebes%20kmubanking-Team%2C%0D%0A%0D%0Agern%20m%C3%B6chte%20ich%20mehr%20erfahren%2C%20vor%20allem%20sehen%2C%20vom%20digitalen%20KMU-Banking%20und%20freue%20mich%20%C3%BCber%20eine%20pers%C3%B6nliche%20Demo%20des%20Prototyps%20und%20der%20weiterf%C3%BChrenden%20Gedanken.%20Meine%20Signatur%20habe%20ich%20dieser%20Mail%20unten%20angef%C3%BCgt.%20Ich%20freue%20mich%20%C3%BCber%20eine%20Kontaktaufnahme.%0D%0A%0D%0AMit%20freundlichen%20Gr%C3%BC%C3%9Fen">
          <Wrapper>
            <ChapterSubtitle>
              next <Arrow />
            </ChapterSubtitle>
            <ChapterLabel>{label}</ChapterLabel>
          </Wrapper>
        </Link>
      )}
      {link !== "" && (
        <Link as={RouterLink} to={link}>
          <Wrapper>
            <ChapterSubtitle>
              next <Arrow />
            </ChapterSubtitle>
            <ChapterLabel>{label}</ChapterLabel>
          </Wrapper>
        </Link>
      )}
    </LinkWrapper>
  );
};

export default NextChapter;
