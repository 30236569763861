import styled, { css, keyframes } from "styled-components";
import { circle, font, position, rwd, sizing } from "styles/Mixins";
import { ReactComponent as ExplosionImage } from "assets/images/exploding.svg";
import Heading from "components/Heading";
import { random } from "styles/Utils";

export const Wrapper = styled.section`
  background: ${({ theme }) => theme.colors.primaryBlue};
  width: 100%;
  position: relative;
  padding: 0 34px;

  padding-bottom: 0;
  overflow: hidden;

  ${rwd({
    medium: `
      padding: 0 ${(160 / 1920) * 100}vw;
      padding-top: ${((970 + 1272) / 1920) * 100}vw;
      padding-bottom: 700px;
    `,
    large: `
      padding-top: ${((970 + 672) / 1920) * 100}vw;
    `,
  })}
`;

export const Circle = styled.div`
  background: ${(props) => props.theme.colors.secondaryBlue};
  ${circle("355px")}
  ${position("absolute", { top: `${(372 / 1920) * 100}vw`, left: "50%" })}
  border-radius: 50%;
  transform: translateX(-50%);
  z-index: 1;
  position: relative;
  transition: height 999999s;

  ${rwd({
    medium: `
    ${position("absolute", { top: `${(372 / 1920) * 100}vw`, left: "50%" })}
    ${circle((970 / 1920) * 100 + "vw")}
    min-width: 586px;
    min-height: 586px;
  `,
  })};
`;

export const InnerCircle = styled.div<{
  color: string;
  width: string[];
  level: number;
}>`
  ${({ width, level }) => circle(width[level])}
  background: ${(props) => props.color};
  ${position("absolute", { top: "50%", right: 0 })}
  transform: translateY(-50%);
  transition: 1.8s cubic-bezier(0.5, 1, 0.89, 1) all;

  &:nth-child(5) {
    transition-delay: 0.2s;
  }

  &:nth-child(4) {
    transition-delay: 0.3s;
  }

  &:nth-child(3) {
    transition-delay: 0.4s;
  }

  &:nth-child(2) {
    transition-delay: 0.5s;
  }

  &:nth-child(1) {
    transition-delay: 0.6s;
  }
`;

export const MarketSharesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 10;
  padding-top: 300px;
  padding-bottom: 200px;

  ${rwd({
    medium: `
      padding: 0;
    `,
  })}
`;

export const MarketSharesRanges = styled.div`
  display: flex;
  row-gap: 16px;
  column-gap: 16px;
  padding: 32px 0;
  flex-direction: column;
  width: 100%;

  ${rwd({
    medium: `
      flex-direction: row;
    `,
  })}
`;

export const MarketShares = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  padding: 32px 0;

  ${rwd({
    medium: `
      display: flex;
      column-gap: ${(132 / 1920) * 100}vw;
      padding: 32px 0;
    `,
  })}
`;

export const MarketShareItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 12px;
  margin: 16px 0;

  ${rwd({
    medium: `
      justify-content: flex-start;
      margin: 0;
    `,
  })}
`;

export const ShareItemLabel = styled.span`
  ${font("BrownLLWeb", "16px", 600)};
  color: ${(props) => props.theme.colors.white};

  ${rwd({
    medium: `
      ${font("BrownLLWeb", "21px", 600)};
    `,
  })}
`;

export const ShareItemValue = styled.span`
  ${font("BrownLLWeb", "25px", 600)};
  color: ${(props) => props.theme.colors.white};

  ${rwd({
    medium: `
      ${font("BrownLLWeb", "38px", 600)};
    `,
  })}
`;

export const MarketRangeItem = styled.button<{ isActive: boolean }>`
  ${font("BrownLLWeb", "18px", 600)}
  width: 100%;
  height: 34px;
  border: solid 1px ${(props) => props.theme.colors.white};
  background-color: transparent;
  color: ${(props) => props.theme.colors.white};
  cursor: pointer;
  margin: 8px 0;

  &:hover {
    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.primaryBlue};
  }

  ${(props) =>
    props.isActive &&
    css`
      background-color: ${(props) => props.theme.colors.white};
      color: ${(props) => props.theme.colors.primaryBlue};
    `}

  ${rwd({
    medium: `
      width: 216px;
      margin: 0;
    `,
  })}
`;

export const Explosion = styled(ExplosionImage)`
  padding: 40px;
  overflow: hidden;
  ${position("absolute", {
    top: (0 / 1920) * 100 + "vw",
    left: (420 / 1920) * 100 * -1 + "vw",
  })}
`;

export const Caption = styled(Heading)`
  ${position("absolute", { top: "140px" })}
  z-index: 2;
  font-size: 60px;

  ${rwd({
    medium: `
      top: ${(276 / 1920) * 100}vw;
      font-size: ${(210 / 1920) * 100}vw;
      line-height: ${(210 / 1920) * 100}vw;
    `,
  })}
`;

export const Particles = styled.div<{ isActive: boolean }>`
  display: none;
  ${sizing("100%")}
  ${position("absolute", { top: 0, left: "50vw" })}


  ${(props) =>
    rwd({
      medium: `
      display: block;
      opacity: 0;
      transition: 2s opacity ease-in;
      ${
        props.isActive &&
        `
        opacity: 1;
      `
      }
    `,
    })}
`;

const createParticlesSettings = (count: number) => {
  let styles = ``;

  for (let i = 0; i < count; i++) {
    styles += `
      &:nth-child(${i + 1}) {
        transform: translate(${random(100)}vw, ${random(100)}vh);
        background: #002b47;
        animation-duration: ${1 + 0.01 * random(4000, 2500)}s;
        animation-delay: ${-0.01 * random(2000) * (1 + 0.01 * random(2000))}s;
      }
    `;
  }

  return css`
    ${styles}
  `;
};

const shoot = keyframes`
  0% {
    transform: translate(-30vw, 10vh);
  }
`;

export const fade = keyframes`
  to {
    opacity: 0;
  }
`;

export const Particle = styled.div`
  ${position("absolute", {})}
  ${sizing(random(9, 6) + "px")}
  animation: ${shoot} 3s ease-out infinite;
  animation-name: ${shoot}, ${fade};
  ${createParticlesSettings(200)}
  border-radius: ${random(20)}px;
`;
