import Heading from "components/Heading";
import styled, { keyframes } from "styled-components";
import { circle, font, position, rwd, sizing } from "styles/Mixins";
import { Wrapper as Button } from "components/Button/Button.style";
import { heroPosition, heroCaptionPosition } from "styles/shared";
import { ReactComponent as ScrollIcon } from "assets/icons/scroll.svg";

export const Wrapper = styled.div`
  ${sizing("100%", "100vh", "max-")}
  width: 100%;
  @media screen and (max-height: 600px) {
    min-height: 1024px;
  }
  margin-bottom: -4px;
  position: relative;
  z-index: 1;
`;

export const BackgroundVideo = styled.video`
  object-fit: cover;
  margin: 0 auto;
  width: 100%;
  height: 100vh;

  @media screen and (max-height: 600px) {
    min-height: 1024px;
  }
`;

export const CircleWrapper = styled.div`
  max-width: 100%;
  overflow: hidden;
`;

export const Circle = styled.div`
  background: transparent;
  border: 2px solid ${(props) => props.theme.colors.white};
  ${circle("486px")}
  ${position("absolute", { top: `${(572 / 1920) * 100}vw`, left: "50%" })}
  border-radius: 50%;
  transform: translateX(-50%);
  z-index: 100;

  ${rwd({
    medium: `
      ${heroPosition}
      ${circle((970 / 1920) * 100 + "vw")}
      min-width: 586px;
      min-height: 586px;
    `,
  })}
`;

export const Caption = styled(Heading)`
  ${position("absolute", { top: (980 / 1680) * 100 + "vw" })}
  display: flex;
  width: 100%;
  justify-content: center;
  z-index: 2;
  font-size: 60px;
  text-align: center;

  ${rwd({
    medium: `
     ${position("absolute", { top: (140 / 1680) * 100 + "vw" })}
      ${heroCaptionPosition}
      font-size: ${(210 / 1920) * 100}vw;
      line-height: ${(210 / 1920) * 100}vw;
    `,
  })}
`;

export const Label = styled.span`
  ${font("BrownLLWeb", `16px`, 400)}
  display: block;
  white-space: pre-line;

  text-align: left;

  color: ${(props) => props.theme.colors.white};

  ${rwd({
    medium: `
      margin: 0 0 0 26px;
      ${font("BrownLLWeb", `${(30 / 1920) * 100}vw`, 400)}
      display: block; 
      white-space: pre-line;
    `,
  })}
`;

export const PlayTrailerWrapper = styled.div`
  ${position("absolute", { top: "-30px", left: "92.4%" })}
  transform: translateX(-50%);
  cursor: pointer;
  display: flex;
  align-items: center;

  width: auto;
  min-width: 100%;
  svg {
    min-width: 60px;
  }

  ${Label} {
    display: none;
  }

  ${rwd({
    medium: `
      transform: translateX(0);
      ${position("absolute", { top: "38%", left: "95.9%" })}

      ${Label} {
        display: initial;
      }
    `,
  })}
`;

export const DemoWrapper = styled.div`
  ${position("absolute", { top: "64%", left: "50%" })}
  transform: translate(-50%, 0%);
  display: flex;
  flex-direction: column;
  z-index: 1;
  align-items: center;

  ${Label} {
    margin-bottom: 20px;
  }

  ${Button} {
    font-size: 16px;
    border: none;
    padding: 12px 22px;
    height: auto;

    ${rwd({
      medium: `
        font-size: 20px;
      `,
    })}
  }

  &:after {
    ${position("absolute", { top: "149%", left: "50%" })}
    transform: translateX(-50%);
    content: "";

    ${circle("32px")}
    background: #fff;
  }

  ${rwd({
    medium: `
      ${position("absolute", { top: "60%", left: "-33px" })}
      align-items: flex-end;
      transform: translate(-100%, -50%);
      &:after {
        ${position("absolute", { top: "50%", right: "-56px", left: "unset" })}
         display: block;
        transform: translateY(-50%);
      }
    `,
  })}
`;

export const Widget = styled.div`
  ${position("absolute", { bottom: 0, right: 0 })}
  background: #fff;
  padding: 20px 16px;
  padding-left: 96px;
  width: 100%;
  ${rwd({
    medium: `
      width: initial;
      ${position("absolute", { bottom: "45px", right: "50px" })}
    `,
  })}
`;

export const WidgetLabel = styled.div`
  ${font("BrownLLWeb", `19px`, 500)}
  color: #002b47;

  ${rwd({
    medium: `
      ${font("BrownLLWeb", `${(21 / 1920) * 100}vw`, 500)}
    `,
  })}
`;

export const DiscoverLink = styled.a`
  display: flex;
  align-items: center;
  column-gap: 6px;

  svg {
    width: 20px;
  }
`;

export const Discover = styled.div`
  display: flex;
  align-items: center;

  ${font("BrownLLWeb", `19px`, 400)}
  color: #009fe3;
  margin-top: 4px;

  a {
    text-decoration: none;
    color: inherit;
    margin-right: 8px;
  }

  ${rwd({
    medium: `
      ${font("BrownLLWeb", `${(21 / 1920) * 100}vw`, 500)}
    `,
  })}
`;

export const Bild = styled.img`
  ${position("absolute", { bottom: "20px", left: "16px" })}
`;

export const jump = keyframes`
  0% {
    transform: translate(13.049px, 20%);
  }
  50% {
    transform: translate(13.049px, 60%);
  }
  100% {
    transform: translate(13.049px, 20%);
  }
`;

export const ScrollDown = styled(ScrollIcon)`
  ${position("absolute", { bottom: (240 / 1920) * 100 + "vw", left: "50%" })}
  transform: translateX(-50%);
  display: none;

  #scroll-wheel {
    animation: ${jump} 2s infinite ease-in-out;
  }

  ${rwd({
    medium: `
    display: block;
    `,
  })}
`;
