import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import React, { useEffect, useLayoutEffect, useRef } from "react";
import {
  Legend,
  LegendItem,
  St3,
  St4,
  St5,
  Wrapper,
} from "./SpherePyramid.style";

export interface ISpherePyramidProps {
  children?: any;
}

const SpherePyramid = ({}: ISpherePyramidProps) => {
  const leftHemiRef = useRef<any | null>(null);
  const rightHemiInnerRef = useRef<any | null>(null);
  const rightHemOuterRef = useRef<any | null>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const legendRef = useRef<HTMLUListElement | null>(null);

  const leftHemiEntryPath =
    "M1041.944 573.358c-132.5-229.4-346-354.2-476.9-278.6h-.1c-232.6 134.3-313.8 429.2-181.3 658.6 132.5 229.4 428.5 306.6 661.1 172.3 130.9-75.5 129.7-322.8-2.8-552.3z";
  const leftHemiEndPath =
    "M724.462 728.32c-132.5-229.4-346-354.2-476.9-278.6h-.1c-232.6 134.3-313.8 429.2-181.3 658.6 132.5 229.4 428.5 306.6 661.1 172.3 130.9-75.5 129.7-322.8-2.8-552.3z";

  const rightOuterHemiEndPath =
    "M1402.462 973.151c8.1-4.7 15.8-10.1 23-16.2 7.2-6.1 13.8-12.7 19.9-19.9 6.2-7.3 11.8-15.2 16.8-23.4 5.2-8.5 9.8-17.4 13.7-26.5 8.4-19.8 14.5-40.4 18.1-61.6 4.1-23.4 6-47.2 5.8-71-.2-26.2-2.4-52.3-6.6-78.2-4.5-28.1-10.8-55.9-18.9-83.2-8.7-29.2-19.1-57.9-31.2-85.9-12.8-29.6-27.4-58.5-43.5-86.5-25.4-44.2-54.9-85.9-88.1-124.6-31.4-36.4-65-68.3-99.8-94.7-16.5-12.6-33.8-24.2-51.8-34.7-16.6-9.7-33.9-18.2-51.7-25.5-16.4-6.7-33.4-12-50.7-15.7-16-3.5-32.3-5.3-48.6-5.4-31.8 0-60.9 7.4-86.3 22.1 19.1-11 38.9-20.8 59.3-29.2a487.11 487.11 0 0 1 308.7-21.1c40 10.4 78.4 25.9 114.5 46 55.1 30.9 103.5 72.5 142.2 122.3 33.3 42.9 59.1 91.2 76.2 142.8 5 15 9.2 30.3 12.6 45.8 3.4 15.2 6 30.8 7.9 46.3 1.8 15.4 2.9 31 3.2 46.6.3 15.4-.1 31-1.3 46.4-4.8 61.9-21.4 122.3-49 177.9-6.9 13.9-14.5 27.7-22.7 40.9-8.2 13.3-17.2 26.3-26.7 38.7-9.6 12.5-19.9 24.8-30.6 36.3-10.9 11.7-22.5 23-34.5 33.5-12.1 10.7-25 20.9-38.2 30.4-13.3 9.9-27.3 19-41.7 27.3z";

  const rightOuterHemiEntryPath =
    "M1043.409 1124.334c8.1-4.7 15.8-10.1 23-16.2 7.2-6.1 13.8-12.7 19.9-19.9 6.2-7.3 11.8-15.2 16.8-23.4 5.2-8.5 9.8-17.4 13.7-26.5 8.4-19.8 14.5-40.4 18.1-61.6 4.1-23.4 6-47.2 5.8-71-.2-26.2-2.4-52.3-6.6-78.2-4.5-28.1-10.8-55.9-18.9-83.2-8.7-29.2-19.1-57.9-31.2-85.9-12.8-29.6-27.4-58.5-43.5-86.5-25.4-44.2-54.9-85.9-88.1-124.6-31.4-36.4-65-68.3-99.8-94.7-16.5-12.6-33.8-24.2-51.8-34.7-16.6-9.7-33.9-18.2-51.7-25.5-16.4-6.7-33.4-12-50.7-15.7-16-3.5-32.3-5.3-48.6-5.4-31.8 0-60.9 7.4-86.3 22.1 19.1-11 38.9-20.8 59.3-29.2a487.11 487.11 0 0 1 308.7-21.1c40 10.4 78.4 25.9 114.5 46 55.1 30.9 103.5 72.5 142.2 122.3 33.3 42.9 59.1 91.2 76.2 142.8 5 15 9.2 30.3 12.6 45.8 3.4 15.2 6 30.8 7.9 46.3 1.8 15.4 2.9 31 3.2 46.6.3 15.4-.1 31-1.3 46.4-4.8 61.9-21.4 122.3-49 177.9-6.9 13.9-14.5 27.7-22.7 40.9-8.2 13.3-17.2 26.3-26.7 38.7-9.6 12.5-19.9 24.8-30.6 36.3-10.9 11.7-22.5 23-34.5 33.5-12.1 10.7-25 20.9-38.2 30.4-13.3 9.9-27.3 19-41.7 27.3z";

  const rightInnerHemiEndPath =
    "M922.562 142.251c130.9-75.6 344.5 49.1 476.9 278.6 132.4 229.5 133.7 476.7 2.8 552.3-130.9 75.6-344.5-49.1-476.9-278.6-132.4-229.5-133.8-476.7-2.8-552.3z";

  const rightInnerHemiEntryPath =
    "M563.508 293.433c130.9-75.6 344.5 49.1 476.9 278.6 132.4 229.5 133.7 476.7 2.8 552.3-130.9 75.6-344.5-49.1-476.9-278.6-132.4-229.5-133.8-476.7-2.8-552.3z";

  useLayoutEffect(() => {
    const tl = gsap.timeline({ paused: true });

    tl.to(
      [
        leftHemiRef.current,
        rightHemOuterRef.current,
        rightHemiInnerRef.current,
      ],
      {
        opacity: 0.19,
        attr: {
          d: (i: number) => {
            if (i === 0) {
              return leftHemiEndPath;
            } else if (i === 1) {
              return rightOuterHemiEndPath;
            } else if (i === 2) {
              return rightInnerHemiEndPath;
            } else {
              return "";
            }
          },
        },
        scrollTrigger: {
          trigger: wrapperRef.current,
          start: "center center-=200",
          end: "+=100%",
          pinSpacing: true,
          scrub: 1,
        },
      },
      0
    ).to(
      [
        leftHemiRef.current,
        rightHemOuterRef.current,
        rightHemiInnerRef.current,
      ],
      {
        scrollTrigger: {
          trigger: wrapperRef.current,
          start: "top+=10% top",
          end: "bottom bottom",
          endTrigger: "#businesscase",
          pin: true,
          pinSpacing: true,
        },
      },
      1
    );
    tl.to(
      [
        leftHemiRef.current,
        rightHemOuterRef.current,
        rightHemiInnerRef.current,
        "#steering-model-floor",
        "#tech-model-floor *",
        "#org-model-floor *",
        "#business-model-floor *",
        "#mission-model-floor *",
      ],
      {
        fill: (i) => (i < 3 ? "transparent" : "#002b47"),
        stroke: "#194969",
        scrollTrigger: {
          trigger: "#mission-model",
          start: "top-=50% top",
          end: "+=20%",
          scrub: 1,
        },
      },
      0
    ).to(
      "#hero",
      {
        backgroundColor: "#002b47",
        scrollTrigger: {
          trigger: wrapperRef.current,
          start: "top top",
          endTrigger: "#mission-model",
          end: "top top",
          scrub: 1,
        },
      },
      1
    );

    return () => {
      tl.kill();
    };
  }, []);

  return (
    <Wrapper ref={wrapperRef}>
      <svg
        viewBox="0 0 1647.4 1349.3"
        xmlSpace="preserve"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          ref={rightHemOuterRef}
          id="right-hemi-outer"
          d={rightOuterHemiEntryPath}
          opacity="1"
          fill="#002B47"
        />
        <path
          ref={rightHemiInnerRef}
          id="right-hemi-inner"
          d={rightInnerHemiEntryPath}
          opacity="1"
          fill="#33556C"
          stroke="#012B48"
          strokeWidth="5"
          strokeLinejoin="round"
        />
        <St3
          id="steering-model-floor"
          d="m824.662 687.62-368.5 209 368.5 211.5 368.5-211.5z"
        />
        <g id="tech-model-floor">
          <St3
            id="Pfad_721"
            d="m824.598 614.376-250.6 142.1 250.6 143.9 250.7-143.8z"
          />
          <St4
            id="Pfad_722"
            d="M824.598 1008.876v-108.5l250.6-143.8 62.3 72.8z"
          />
          <St5
            id="Pfad_723"
            d="M824.598 1008.876v-108.5l-250.6-143.9-62.3 72.8z"
          />
        </g>
        <g id="org-model-floor">
          <St3
            id="Pfad_724"
            d="m824.694 548.799-187 101.9 186.9 106.7 186.9-106.8z"
          />
          <St4
            id="Pfad_725"
            d="M824.694 864.199v-106.8l186.9-106.8 63.8 69.7z"
          />
          <St5
            id="Pfad_726"
            d="M824.594 864.199v-106.8l-186.9-106.7-63.7 69.7z"
          />
        </g>
        <g id="business-model-floor">
          <St3
            id="Pfad_727"
            d="m824.606 476.362-124.7 66.5 124.8 70.2 125-70z"
          />
          <St4 id="Pfad_728" d="M824.606 721.362v-108.3l125-70 61.9 71.5z" />
          <St5
            id="Pfad_729"
            d="M824.606 721.362v-108.3l-124.7-70.2-62.2 71.8z"
          />
        </g>
        <g id="mission-model-floor">
          <St4
            id="Pfad_730"
            d="m824.67 368.883-124.8 137.9 124.8 70.2 125-70z"
          />
          <St5 id="Pfad_731" d="m824.67 368.883-124.8 137.9 124.8 70.2z" />
        </g>
        <path
          ref={leftHemiRef}
          id="left-hemi"
          d={leftHemiEntryPath}
          opacity="1"
          fill="#002B47"
          stroke="#012B48"
          strokeWidth="5"
          strokeLinejoin="round"
        />
      </svg>
    </Wrapper>
  );
};

export default SpherePyramid;
