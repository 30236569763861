import Section from 'components/Section';
import React from 'react'
import { useTranslation } from 'react-i18next';
import SegmentsChart from './components/SegmentsChart';

const BankServicing = () => {
  const { t } = useTranslation("customer");
  return (
    <Section color="light" title={t("heading-2")} excerpt={t("content-2")}>
      <SegmentsChart />
    </Section>
  );
}

export default BankServicing