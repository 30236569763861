import {Wrapper as Heading} from "components/Heading/Heading.style";
import styled from "styled-components";
import { font, rwd } from "styles/Mixins";

export const Wrapper = styled.div`
  width: 100%;
  margin-top: -55px;

  ${rwd({
    medium: `
      border-top: 2px solid ${(props: any) => props.theme.colors.primaryBlue};;
      padding-top: 30px;
      margin-top: 0;
    `,
  })}

  ${Heading} {
    display: none;

    ${rwd({
      medium: `
        display: block;
        padding-bottom: 44px;
      `
    })}
  }
`;

export const List = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  margin-bottom: 32px;
`;

export const Label = styled.li`
  ${font("BrownLLWeb", "19px", 400)}
  color: ${(props) => props.theme.colors.primaryBlue};
  padding: 8px 0;
  transition: opacity 0.2s ease-in;

  ${rwd({
    medium: `
       ${font("BrownLLWeb", "21px", 400)}
      padding: 12px 0;
    `,
  })}
`;

export const Item = styled.li`
  color: $white;
  ${font("BrownLLWeb", "19px", 400)}
  padding: 8px 0;
  transition: opacity 0.2s ease-in;

  ${rwd({
    medium: `
      ${font("BrownLLWeb", "21px", 400)}
      padding: 12px 0;
    `,
  })}
`;

export const ReadMore = styled.button`
  display: flex;
  width: 100%;
  ${font('BrownLLWeb', '21px', 400)}
  color: ${props => props.theme.colors.primaryBlue};
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  justify-content: center;

  ${rwd({
    medium: `
      justify-content: flex-start;
      display: none;
    `,
  })}
`;
