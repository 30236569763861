import React, { FC, useEffect, useRef, useState } from "react";
import { createGrid } from "./utils";
import gsap from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import { useTranslation } from "react-i18next";
import { Footer, Grid, Legend, LegendItem, Point } from "./SMETutnover.style";

export interface ISMETurnoverProps {}

gsap.registerPlugin(ScrollTrigger)

const gapHash: any = {
  750: 4,
  970: 4,
  1200: 14,
  1400: 14,
};

const SMETurnover: FC<ISMETurnoverProps> = ({}) => {
  const grid = createGrid(33, 10, 290);
  const [pointSize, setPointSize] = useState(24);
  const containerRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation("customer");

  useEffect(() => {
    gsap.fromTo(
      `${Point}`,
      { scale: 0 },
      {
        duration: 0.2,
        scale: 1,
        stagger: {
          grid: "auto",
          from: "start",
          axis: "x",
          amount: 1.5,
        },
        scrollTrigger: {
          trigger: containerRef.current,
          start: "top bottom",
        },
      }
    );
  }, []);

  useEffect(() => {
    const cb = () => {
      const width = containerRef.current?.getBoundingClientRect().width || 0;
      const gap = Object.keys(gapHash).reduce((gap, key) => {
        if (Number(key) <= width) {
          return gapHash[key];
        } else {
          return gap;
        }
      }, 4);

      setPointSize((width - 40 - 32 * gap) / 33);
    };

    window.addEventListener("resize", cb, { passive: true });
    cb();
    return () => window.removeEventListener("resize", cb);
  }, []);

  return (
    <>
      <Grid ref={containerRef}>
        {grid.map((row) =>
          row.map((isActive) => <Point isActive={isActive} size={pointSize} />)
        )}
      </Grid>
      <Footer>
        <Legend>
          <LegendItem>
            <span>unter 1 MEUR Umsatz: 2,9 Mio.</span>
          </LegendItem>
          <LegendItem>
            <span>über 1 MEUR Umsatz: 0,4 Mio.</span>
          </LegendItem>
        </Legend>
        <Legend>
          <LegendItem>
            <Point size={12} />
            <span>
              = 10.000 KMU
            </span>
          </LegendItem>
        </Legend>
      </Footer>
    </>
  );
};

export default SMETurnover;
