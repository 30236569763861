import styled, { css, keyframes } from "styled-components";
import { font, position, rwd } from "styles/Mixins";
import { ReactComponent as SwipeIcon } from "assets/icons/swipe.svg";

export const Wrapper = styled.div`
  margin: 0 -20px;

  ${rwd({
    small: `
      overflow: visible;
      margin-right: 0;
    `,
    medium: `
      padding-top: 0;
    `,
  })}

  .blocks {
    clip-path: url(#segments);

    ${rwd({
      medium: `
      clip-path: none;`,
    })}
  }
`;

const swipeAnimation = keyframes`
  0% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(25deg);
  }
  50% {
    transform: rotate(0);
  }
  75% {
    transform: rotate(-25deg);
  }
  100% {
    transform: rotate(0);
  }
`;

export const Swipe = styled(SwipeIcon)`
  ${position("absolute", { bottom: "64px", left: "66px" })}
  ${rwd({
    medium: `display: none;`,
  })}
  transform-origin: 50% 100%;
  animation: ${swipeAnimation} 6s infinite ease-in-out;
`;

export const Canvas = styled.div`
  width: 100vw;
  height: 71vh;
  position: relative;

  ${rwd({
    medium: `
      width: 100%;
      height: auto;
      `,
  })}
`;

export const Chart = styled.svg`
  width: calc(1080 / 375 * 100vw - 20px);
  height: auto;
  margin-top: 48px;

  ${rwd({
    small: `
      width: initial;
      height: initial;
    `,
  })}
`;

export const Track = styled.rect`
  width: 1396px;
  height: 376px;
  fill: ${(props) => props.theme.colors.tertiaryBlue};
`;

export const TrackLabel = styled.text`
  ${font("BrownLLWeb", "21px", 500)}
  fill: ${(props) => props.theme.colors.secondaryBlue};
  ${rwd({
    medium: `
      font-weight: 600;
    `,
  })}
`;

export const TrackLabelCap = styled.text`
  ${font("BrownLLWeb", "21px", 400)}
  fill: ${(props) => props.theme.colors.secondaryBlue};
`;

export const Block = styled.rect`
  fill: ${(props) => props.theme.colors.secondaryBlue};
  transition: 0.2s ease-in-out all;
  clip-path: url(#segments);

  ${rwd({
    small: `
     transition: 0.2s ease-in-out all;
    `,
    medium: `
    clip-path: none;`,
  })}
`;

export const BlockLabel = styled.text`
  ${font("BrownLLWeb", "16px", 500)}
  fill: transparent;
  pointer-events: none;
  transition: 0.2s ease-in-out fill;

  ${rwd({
    medium: `
    font-weight: 600;
    `,
  })}
`;

export const BlockValue = styled(BlockLabel)`
  color: ${(props) => props.theme.colors.primaryBlue};
`;

export const SegmentLabel = styled.text`
  ${font("BrownLLWeb", "18px", 400)}
  display: initial;
  fill: ${(props) => props.theme.colors.secondaryBlue};
`;

export const PaginationWrapper = styled.g`
  ${rwd({
    medium: `
    display: none;
  `,
  })}
`;

export const Dot = styled.circle<{ isActive: boolean }>`
  fill: #fff;

  ${(props) =>
    props.isActive &&
    css`
      fill: ${(props) => props.theme.colors.secondaryBlue};
    `}
`;
